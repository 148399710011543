import { Loader } from '@fountain/fountain-ui-components';
import {
  Divider,
  Grid,
  Hidden,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { ReadyState } from 'api/resultStatus';
import { NotificationPreferences } from 'api-clients/monolith';
import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { REACT_APP_MONOLITH_BASE_URL } from 'runtimeEnvVars';

import BackButton from 'components/BackButton';
import GlobalNav from 'components/GlobalNav';
import { makeSelectWhoami } from 'containers/Auth_old/selectors';

import messages from './messages';
import { NotificationPreferenceTableBody } from './NotificationPreferenceTableBody';
import { useStyles } from './styles';
import { useNotifiableData } from './useNotifiableData';

interface RouteParams {
  notifiableId: string;
  notifiableType: string;
}

export const ManageNotificationPreference: React.FC = () => {
  const styles = useStyles();
  const { notifiableId, notifiableType } = useParams<RouteParams>();
  const { account_name: accountSlug } = useSelector(makeSelectWhoami());

  if (notifiableId === undefined) return <></>;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { result } = useNotifiableData({ notifiableType, notifiableId });

  if (result.isLoading) return <Loader fullScreen />;

  const {
    data: {
      notifiable_name: notifiableName,
      notification_preferences: notificationPreferences,
    },
  } = result as ReadyState<NotificationPreferences>;

  if (!notificationPreferences) return <></>;

  const rolesPageUrl = `${REACT_APP_MONOLITH_BASE_URL}/${accountSlug}/roles`;

  const handleBack = () => {
    if (notifiableType === 'user_roles') window.location.href = rolesPageUrl;
    else window.history.back();
  };

  return (
    <>
      <Helmet>
        <title>Manage Notifications </title>
        <meta name="description" content="AI Jobs Directory Career Site" />
      </Helmet>
      <Grid container direction="column">
        <Hidden smDown>
          <GlobalNav />
        </Hidden>

        <Grid
          container
          direction="column"
          className={styles.container}
          justify="space-between"
          wrap="nowrap"
        >
          <Grid item container direction="column">
            <BackButton
              onClick={handleBack}
              data-testid="backButton"
              className={styles.backButton}
            />
            <Typography display="inline" variant="h2" className={styles.header}>
              <FormattedMessage {...messages.header} /> {notifiableName}
            </Typography>

            <Grid item>
              <Typography display="inline" variant="body1">
                <FormattedMessage {...messages.subTitle} />
              </Typography>
            </Grid>

            <Divider className={styles.divider} />
          </Grid>

          <Grid item container direction="column" className={styles.content}>
            <Table role="table" padding="none" className={styles.table}>
              <TableHead className={styles.tableHead}>
                <TableRow className={styles.tableRow}>
                  <TableCell></TableCell>
                  <TableCell className={styles.tableCell}>Trigger</TableCell>
                  <TableCell className={styles.tableCell}>Stages</TableCell>
                  <TableCell className={styles.tableCell}>
                    Last edited
                  </TableCell>
                </TableRow>
              </TableHead>
              {notificationPreferences && (
                <NotificationPreferenceTableBody
                  notifiableType={notifiableType}
                  notifiableId={notifiableId}
                  notificationPreferences={notificationPreferences}
                />
              )}
            </Table>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
