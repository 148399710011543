import { theme } from '@fountain/fountain-ui-components';
import {
  PaginateJsonSchema,
  UserNotificationPreferencesService,
} from 'api-clients/monolith';
import { debounce, isEmpty, uniq } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Select from 'react-select';

import messages from './messages';
import { StageOptionType, StageType } from './types';

const PER_PAGE = 10;

export interface SelectStageProps {
  notifiableType: string;
  notifiableId: string;
  stageType: StageType;
  selectedStages: StageOptionType[];
  onChange: (selectedStages: StageOptionType[]) => void;
}

export const SelectStage: React.FC<SelectStageProps> = ({
  notifiableType,
  notifiableId,
  stageType,
  selectedStages,
  onChange,
}) => {
  const intl = useIntl();
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>('');
  const [stageOptions, setStageOptions] = useState<StageOptionType[]>([]);
  const [pagination, setPagination] = useState({
    currentPage: 0,
    nextPage: 1,
  });
  useEffect(() => {
    setPagination({ currentPage: 0, nextPage: 1 });
  }, [searchInput]);

  const updatePaginationInfo = (paginationData: PaginateJsonSchema) => {
    const camelizedPagination = {
      currentPage: paginationData.current_page,
      nextPage: Number(paginationData.next_page),
    };
    setPagination(camelizedPagination);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getStageOptions = useCallback(
    debounce((query: string) => {
      UserNotificationPreferencesService.getInternalApiNotificationPreferencesStages(
        notifiableType,
        notifiableId,
        stageType,
        query,
        pagination.currentPage ?? 1,
        PER_PAGE,
      )
        .then(result => {
          updatePaginationInfo(result.pagination);
          const updatedStages =
            result.pagination.current_page === 1
              ? result.stages
              : uniq([...stageOptions, ...result.stages]);
          setStageOptions(updatedStages);

          setIsloading(false);
        })
        .catch(() => {
          setIsloading(false);
        });
    }, 1000),
    [pagination.currentPage, stageOptions],
  );

  useEffect(() => {
    if (pagination.nextPage !== 0) {
      setIsloading(true);
      getStageOptions(searchInput);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.currentPage]);

  return (
    <Select
      name={`CustomStagesSelect-${notifiableType}-${notifiableId}`}
      styles={{
        multiValue: provided => ({
          ...provided,
          '&:hover': {
            backgroundColor: theme.palette.common.blue200,
          },
          alignItems: 'center',
          backgroundColor: theme.palette.common.blue100,
          borderRadius: theme.highlightBorderRadius,
          display: 'flex',
          gap: theme.spacing(1),
          margin: theme.spacing(0.25),
          marginLeft: 'unset',
          padding: theme.spacing(1, 1.75, 1, 1.25),
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 }),
      }}
      isMulti
      canSelectAll={false}
      menuPortalTarget={document.body}
      options={stageOptions}
      onChange={options =>
        isEmpty(options)
          ? onChange([])
          : // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            Array.isArray(options) && onChange(options)
      }
      placeholder={intl.formatMessage(messages.selectPlaceolderText)}
      value={selectedStages}
      closeMenuOnSelect={false}
      inputValue={searchInput}
      onInputChange={value => {
        setSearchInput(value);
      }}
      hideSelectedOptions={false}
      isClearable
      noOptionsMessage={() => (isLoading ? 'Loading ...' : 'No Result')}
      filterOption={() => true}
      onMenuScrollToBottom={() =>
        setPagination({
          ...pagination,
          currentPage: pagination.nextPage,
        })
      }
    />
  );
};
