import { SupportedFilterableTypes } from './types';

export interface Filterable extends Record<string, string> {
  title: string;
  id: string;
  name: SupportedFilterableTypes;
}

type FilterableName = Filterable['name'];

export const mapFilterable = <T extends string | object>(
  items: T[] | undefined,
  options: {
    name: FilterableName;
    titleAttr?: string;
    idAttr?: string;
  },
): Filterable[] => {
  if (!items?.length) return [];

  return items.map(item => {
    if (typeof item === 'string') {
      return { title: item, id: item, name: options.name };
    }

    if (!options.titleAttr || !options.idAttr) {
      throw new Error(
        'titleAttr and idAttr are required for non-string arrays',
      );
    }

    const record = item as Record<string, unknown>;

    return {
      title: String(record[options.titleAttr]),
      id: String(record[options.idAttr]),
      name: options.name,
    };
  });
};
