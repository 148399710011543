import {
  ConfirmationModal,
  Loader,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@fountain/fountain-ui-components';
import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  Typography,
} from '@material-ui/core';
import {
  CustomNotificationPreferenceParams,
  NotificationPreference,
  UserNotificationPreferencesService,
} from 'api-clients/monolith';
import { capitalize, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { addMessageAction } from 'containers/FlashMessage/actions';

import messages from './messages';
import { SelectOpeningStage } from './SelectOpeningStage';
import { SelectWorkflowStage } from './SelectWorkflowStage';
import { StageType } from './types';

export interface CustomizeNotificationPreferenceModalProps {
  notifiableType: string;
  notifiableId: string;
  notificationPreference: NotificationPreference;
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  updateNotificationPreference: (
    key: string,
    updatedNotificationPreference: NotificationPreference,
  ) => void;
}

export type SelectedStage = {
  stageId: number;
  stageName: string;
  funnelId: number | undefined;
};

export const CustomizeNotificationPreferenceModal: React.FC<
  CustomizeNotificationPreferenceModalProps
> = ({
  notifiableType,
  notifiableId,
  notificationPreference,
  modalOpen,
  setModalOpen,
  updateNotificationPreference,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [stagesData, setStagesData] = useState<
    CustomNotificationPreferenceParams[]
  >([]);
  const stagTypes: StageType[] = ['workflow', 'opening'];
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);

  const initialStageType =
    notificationPreference?.custom_notification_preferences &&
    notificationPreference?.custom_notification_preferences[0]?.funnel_id
      ? 'opening'
      : 'workflow';
  const [selectedStageType, setSelectedStageType] =
    useState<StageType>(initialStageType);

  useEffect(() => {
    setModalOpen(!showConfirmationModal);
  }, [setModalOpen, showConfirmationModal]);

  const updateStageType = (stageType: StageType) => {
    setSelectedStageType(stageType);
  };

  const savePreferences = () => {
    setShowConfirmationModal(false);
    setIsloading(true);

    void UserNotificationPreferencesService.putInternalApiNotificationPreferencesUpdateCustomPreferences(
      notifiableType,
      notifiableId,
      notificationPreference.key,
      {
        custom_notification_preferences: stagesData,
      },
    )
      .then(result => {
        setModalOpen(false);
        updateNotificationPreference(notificationPreference.key, result);
      })
      .catch(() => {
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.notificationUpdateErrored),
            'error',
          ),
        );
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  const resetChanges = () => {
    setSelectedStageType(initialStageType);
    setModalOpen(false);
  };

  const onSavePreference = () => {
    // if stageType is changed and there is atleast one stage selected
    if (
      selectedStageType !== initialStageType &&
      !isEmpty(notificationPreference.custom_notification_preferences)
    )
      setShowConfirmationModal(true);
    else {
      savePreferences();
    }
  };

  const renderStageTypeOption = (stageType: StageType) => (
    <FormControlLabel
      control={
        <Radio
          checked={selectedStageType === stageType}
          onChange={() => updateStageType(stageType)}
          value={stageType}
        />
      }
      label={capitalize(stageType)}
    />
  );

  return (
    <>
      <Modal
        open={modalOpen}
        id={notificationPreference.key}
        data-testid="CustomNotificationPreferenceModal"
      >
        <ModalHeader
          ariaLabelledBy={intl.formatMessage(messages.modalHeader)}
          onClose={resetChanges}
          showIcon={false}
        >
          <Typography variant="subtitle2">
            <FormattedMessage {...messages.modalHeader} />
          </Typography>
          <Typography variant="caption">
            <FormattedMessage {...messages.modalSubHeader} />
          </Typography>
        </ModalHeader>
        <ModalContent>
          <Grid container>
            <Grid item alignContent="flex-start">
              {stagTypes.map(renderStageTypeOption)}
            </Grid>
            <Grid item md={12}>
              {selectedStageType === 'opening' && (
                <SelectOpeningStage
                  notifiableType={notifiableType}
                  notifiableId={notifiableId}
                  notificationPreference={notificationPreference}
                  setStagesData={setStagesData}
                />
              )}
              {selectedStageType === 'workflow' && (
                <SelectWorkflowStage
                  notifiableType={notifiableType}
                  notifiableId={notifiableId}
                  notificationPreference={notificationPreference}
                  setStagesData={setStagesData}
                />
              )}
            </Grid>
          </Grid>
        </ModalContent>
        <ModalFooter>
          <Button size="small" variant="outlined" onClick={resetChanges}>
            <FormattedMessage {...messages.closeModal} />
          </Button>
          <Button
            type="submit"
            size="small"
            variant="contained"
            color="primary"
            onClick={onSavePreference}
            disabled={isLoading}
          >
            <FormattedMessage {...messages.saveCustomPreference} />
          </Button>
        </ModalFooter>
      </Modal>
      {showConfirmationModal && (
        <ConfirmationModal
          actionButtonText={intl.formatMessage(
            messages.confirmationModalActionText,
          )}
          handleClose={() => setShowConfirmationModal(false)}
          handleSubmit={() => savePreferences()}
          modalContentText={intl.formatMessage(
            messages.confirmationModalContent,
            {
              previousStageType: initialStageType,
              currentStageType: selectedStageType,
            },
          )}
          modalTitle={intl.formatMessage(messages.confirmationModalTitle)}
          hideHeaderIcon
        />
      )}
      {isLoading && <Loader fullScreen size="2rem" />}
    </>
  );
};
