import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import { Action } from 'api-clients/monolith';
import { produce } from 'immer';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';

import { getActionErrorMessage } from 'containers/WorkflowEditor/components/Rules/Action/constants';
import {
  RulesProps,
  SharedActionProps,
} from 'containers/WorkflowEditor/components/Rules/types';
import { RulesEditDataContext } from 'containers/WorkflowEditor/contexts/rulesEditDataContext';

import { NewPartner } from '../../Condition/components/PartnerSelect/PartnerSelect';
import { messages } from './messages';

export const ResetPartnerStatus: FC<SharedActionProps> = props => {
  const intl = useIntl();
  const { partner_ids: partners } = useContext(RulesEditDataContext);

  const onChangePartner = (newPartner: NewPartner) => {
    props.setRules(
      produce((draftRules: RulesProps) => {
        const draftActions =
          draftRules[props.ruleId]?.action_set_attributes?.actions_attributes ??
          [];

        const idx = draftActions.findIndex(
          (act: Action) => act.id === props.action.id,
        );

        draftActions[idx].extra.partner_id = newPartner.value;
      }),
    );
  };
  const selectedPartner =
    partners?.find(option => option.value === props.action.extra.partner_id) ??
    null;

  const partnerIdErrorMessage =
    !props.action.extra?.partner_id &&
    props.errors !== undefined &&
    getActionErrorMessage('partner', props.errors);

  return (
    <Grid item xs={6}>
      <StyledReactSelect
        options={partners}
        value={selectedPartner}
        onChange={onChangePartner}
        label={intl.formatMessage(messages.partner)}
        aria-label={intl.formatMessage(messages.partner)}
        isSearchable
        error={partnerIdErrorMessage}
        placeholder=""
        required
      />
    </Grid>
  );
};
