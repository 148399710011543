import {
  ApplicantBlob,
  ApplicantDetailsDataAddress,
  DataType,
  FilePayload,
  MergedDetail,
  Payload,
  ValidationErrors,
} from 'containers/DataValidationModal/types';
import { ApplicantDetailDataDetailsDataValue } from 'hooks/useApplicantDetail.d';

export const isAddressType = (
  value: ApplicantDetailDataDetailsDataValue,
): value is ApplicantDetailsDataAddress =>
  !!value &&
  typeof value === 'object' &&
  !Array.isArray(value) &&
  'original_address' in (value as ApplicantDetailsDataAddress);

export const validateAddress = (address: ApplicantDetailsDataAddress) => {
  const errors: {
    [key in keyof Partial<ApplicantDetailsDataAddress>]: boolean;
  } = {};
  const fields: Array<keyof Partial<ApplicantDetailsDataAddress>> = [
    'street_name',
    'city',
    'zipcode',
    'state',
    'country',
  ];
  fields.forEach(key => {
    if (!address[key]) {
      errors[key] = true;
    }
  });
  return errors;
};

export const applicantHasFileUploaded = (
  key: string,
  blobs?: ApplicantBlob[],
) => {
  const applicantBlob =
    (blobs && blobs.filter(blob => blob.fieldname === key)) || [];
  return !!applicantBlob.length;
};

const translateRubyRegex = (regex: string) => {
  return regex.replace('\\A', '^').replace('\\z', '$');
};

export const validatePayload = ({
  applicantDataFields,
  blobs,
  filePayload,
  payload,
  currentInput,
  hasLogicJumps,
}: {
  applicantDataFields: MergedDetail[];
  blobs?: ApplicantBlob[];
  filePayload: FilePayload;
  payload: Payload;
  currentInput: number;
  hasLogicJumps?: boolean;
}) => {
  const types: DataType[] = ['data', 'secure_data', 'structured_data'];

  let updatedApplicantDataFields = [...applicantDataFields];

  const missingKeys: ValidationErrors = {};

  if (hasLogicJumps) {
    const currentDataField = updatedApplicantDataFields[currentInput];
    updatedApplicantDataFields = [currentDataField];
  }

  const dataFields: { [key in DataType]: MergedDetail[] } = {
    data: updatedApplicantDataFields.filter(
      dataField => dataField.dataFieldType === 'data',
    ),
    secure_data: updatedApplicantDataFields.filter(
      dataField => dataField.dataFieldType === 'secure_data',
    ),
    structured_data: updatedApplicantDataFields.filter(
      dataField => dataField.dataFieldType === 'structured_data',
    ),
  };

  types.forEach(type => {
    dataFields[type].forEach(
      ({
        key,
        required,
        type: fieldType,
        format_source: formatSource,
        format_validation_message: formatMessage,
      }) => {
        if (fieldType === 'text_field' && formatSource && formatMessage) {
          const re = new RegExp(translateRubyRegex(formatSource));
          const value = payload[type][key] as string;
          if (value && !re.test(value)) {
            missingKeys[key] = formatMessage;
          }
        }
        if (required) {
          // handle `File` types
          if (
            fieldType === 'file' &&
            !filePayload[key] &&
            !applicantHasFileUploaded(key, blobs)
          ) {
            missingKeys[key] = true;
          }

          // handle `Field` types
          if (payload && payload[type]) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const payloadValue = payload[type][key];

            if (isAddressType(payloadValue)) {
              const addressErrors = validateAddress(payloadValue);
              if (Object.keys(addressErrors).length) {
                missingKeys[key] = addressErrors;
                return;
              }
            }

            if (
              (Array.isArray(payloadValue) && payloadValue.length === 0) ||
              (fieldType !== 'file' && !payloadValue)
            ) {
              missingKeys[key] = true;
            }
          }
        }
      },
    );
  });

  return missingKeys;
};
