import { CustomNotificationPreference } from 'api-clients/monolith';

export const createDeletedStageData = (
  deletedStages: CustomNotificationPreference[],
) =>
  deletedStages.map(preference => {
    return {
      id: preference.id,
      entity_id: preference.entity_id,
      entity_type: preference.entity_type,
      funnel_id: preference.funnel_id ?? null,
      _destroy: true,
    };
  });
