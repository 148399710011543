import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React, { FC } from 'react';

export const AIInterviewIcon: FC<SvgIconProps> = props => (
  <SvgIcon
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.69231 2C3.94721 2 4.15385 2.20664 4.15385 2.46154V3.23077H4.92308C5.17798 3.23077 5.38462 3.43741 5.38462 3.69231C5.38462 3.94721 5.17798 4.15385 4.92308 4.15385H4.15385V4.92308C4.15385 5.17798 3.94721 5.38462 3.69231 5.38462C3.43741 5.38462 3.23077 5.17798 3.23077 4.92308V4.15385H2.46154C2.20664 4.15385 2 3.94721 2 3.69231C2 3.43741 2.20664 3.23077 2.46154 3.23077H3.23077V2.46154C3.23077 2.20664 3.43741 2 3.69231 2ZM8.61539 2C8.81405 2 8.99042 2.12712 9.05324 2.31559L10.3902 6.32648L13.7005 7.56785C13.8807 7.6354 14 7.80761 14 8C14 8.19239 13.8807 8.3646 13.7005 8.43215L10.3902 9.67352L9.05324 13.6844C8.99042 13.8729 8.81405 14 8.61539 14C8.41673 14 8.24035 13.8729 8.17753 13.6844L6.84057 9.67352L3.53025 8.43215C3.35011 8.3646 3.23077 8.19239 3.23077 8C3.23077 7.80761 3.35011 7.6354 3.53025 7.56785L6.84057 6.32648L8.17753 2.31559C8.24035 2.12712 8.41673 2 8.61539 2ZM4.30769 10.6154C4.56259 10.6154 4.76923 10.822 4.76923 11.0769V11.8462H5.53846C5.79336 11.8462 6 12.0528 6 12.3077C6 12.5626 5.79336 12.7692 5.53846 12.7692H4.76923V13.5385C4.76923 13.7934 4.56259 14 4.30769 14C4.05279 14 3.84615 13.7934 3.84615 13.5385V12.7692H3.07692C2.82202 12.7692 2.61538 12.5626 2.61538 12.3077C2.61538 12.0528 2.82202 11.8462 3.07692 11.8462H3.84615V11.0769C3.84615 10.822 4.05279 10.6154 4.30769 10.6154Z"
      fill="white"
    />
  </SvgIcon>
);
