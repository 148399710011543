import { Grid, Paper, Tooltip, Typography } from '@material-ui/core';
import cx from 'classnames';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Cancel from 'images/cancel.svg';

import { messages } from '../messages';
import { useStyles } from '../styles';
import { SupportedFilterableTypes } from '../types';
import { Filterable } from '../util';
import Close from './close.svg';
import Down from './down.svg';

export interface FilterDropdownButtonProps {
  error?: boolean;
  name: SupportedFilterableTypes;
  open: boolean;
  openUp: () => void;
  count: number;
  clearFilter: () => void;
  titles: string[];
  showTitle?: boolean;
  setSelected?: (selected: Filterable[]) => void;
  selected?: Filterable[];
  customPlaceholder?: React.ReactNode;
}

export const FilterDropdownButton = React.forwardRef<
  HTMLDivElement,
  FilterDropdownButtonProps
>(
  (
    {
      name,
      open,
      openUp,
      count = 0,
      clearFilter,
      titles,
      showTitle,
      setSelected,
      selected,
      error = false,
      customPlaceholder,
    },
    ref,
  ) => {
    const classes = useStyles();
    const intl = useIntl();

    const updateSelectedTitles = (title: string) => {
      if (setSelected) {
        const selectedValue =
          selected && selected.filter(item => item.title !== title);
        setSelected(selectedValue ?? []);
      }
    };
    const openpill = showTitle ? (
      <>
        {titles.map(title => (
          <Grid item className={classes.rounded} key={title}>
            <Typography className={classes.centered}>
              {title}

              <button
                onClick={() => updateSelectedTitles(title)}
                type="button"
                className={classes.invisibleButton}
              >
                <img src={Close} alt={`clear ${name}`} />{' '}
              </button>
            </Typography>
          </Grid>
        ))}
      </>
    ) : open ? (
      <Grid item className={classes.rounded}>
        {name === 'custom' ? (
          <>{customPlaceholder}</>
        ) : (
          <FormattedMessage {...messages[name]} values={{ count }} />
        )}

        <button
          onClick={clearFilter}
          type="button"
          className={classes.invisibleButton}
        >
          <img src={Close} alt={`clear ${name}`} />{' '}
        </button>
      </Grid>
    ) : (
      <Tooltip
        title={titles.map(title => (
          <Typography className={classes.smallTitle} key={title}>
            {title}
          </Typography>
        ))}
      >
        <Grid item className={classes.rounded}>
          {name === 'custom' ? (
            <>{customPlaceholder}</>
          ) : (
            <FormattedMessage {...messages[name]} values={{ count }} />
          )}
          <button
            onClick={clearFilter}
            type="button"
            className={classes.invisibleButton}
          >
            <img src={Close} alt={`clear ${name}`} />
          </button>
        </Grid>
      </Tooltip>
    );
    const pill =
      count > 0 ? (
        <>
          {openpill}
          <Grid
            item
            className={classes.spacing}
            onClick={openUp}
            onKeyDown={openUp}
          ></Grid>
        </>
      ) : (
        <Grid
          item
          className={classes.spacing}
          onClick={openUp}
          onKeyDown={openUp}
        >
          {name === 'custom' ? (
            <>{customPlaceholder}</>
          ) : (
            <FormattedMessage {...messages[name]} values={{ count }} />
          )}
        </Grid>
      );

    const cleanAllButton = showTitle && count > 0 && (
      <Grid onClick={clearFilter} className={classes.plainIcon}>
        <img
          src={Cancel}
          alt={intl.formatMessage(messages.clearAll)}
          className={classes.cleanAllButton}
        />
      </Grid>
    );

    return (
      <Paper
        ref={ref}
        className={cx(
          error && classes.errorButton,
          open ? classes.openButton : classes.closedButton,
        )}
        elevation={0}
      >
        <Grid
          className={classes.buttonInnerContainer}
          id={`filter-${name}-btn`}
        >
          {pill}
          {cleanAllButton}
          <Grid onClick={openUp} className={classes.plainIcon}>
            <img
              src={Down}
              alt="open"
              className={classes[open ? 'openCaret' : 'closedCaret']}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  },
);

FilterDropdownButton.displayName = 'FilterDropdownButton';
