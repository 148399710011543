import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: theme.spacing(3),
    padding: theme.spacing(0.5),
    color: theme.palette.common.white,
    borderRadius: theme.spacing(0.25),
  },
  archivedStageIcon: {
    backgroundColor: theme.palette.common.gray600,
  },
  assessmentStageIcon: {
    backgroundColor: '#212121',
  },
  backgroundCheckIcon: {
    backgroundColor: '#AB3567',
  },
  customStageIcon: {
    backgroundColor: '#F6D057',
  },
  documentSigningStageIcon: {
    backgroundColor: '#11B1B7',
  },
  formStageIcon: {
    backgroundColor: '#64C8E6',
  },
  dataCollectionStageIcon: {
    backgroundColor: '#9790E7',
  },
  ReviewStageIcon: {
    backgroundColor: '#C245A2',
  },
  hiredStageIcon: {
    backgroundColor: '#00B473',
  },
  learningStageIcon: {
    backgroundColor: '#C896FF',
  },
  multiStageIcon: {
    backgroundColor: '#FF8CCC',
  },
  aiInterviewStageIcon: {
    backgroundColor: '#9664FF',
  },
  partnerStageIcon: {
    backgroundColor: '#9664FF',
  },
  rejectedStageIcon: {
    backgroundColor: '#FF4141',
  },
  schedulerStageIcon: {
    backgroundColor: '#6FD8AC',
  },
  techCheckStageIcon: {
    backgroundColor: '#6889E2',
  },
  ruleStageIcon: {
    backgroundColor: theme.palette.common.gray300,
    color: theme.palette.common.gray600,
  },
  jobSelectorStageIcon: {
    backgroundColor: '#FFA963',
  },
  jobSwitcherStageIcon: {
    backgroundColor: '#FC6B3D',
  },
  videoRecordingStageIcon: {
    backgroundColor: '#008773',
  },
}));
