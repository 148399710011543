import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme =>
  createStyles({
    backButton: {
      marginBottom: theme.spacing(1),
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      padding: theme.spacing(2, 7),
      maxWidth: 1200,
      margin: 'auto',
      overflowY: 'auto',
      height: 'calc(100vh - (52px))',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
    divider: {
      marginTop: theme.spacing(3),
    },
    content: {
      flex: 1,
    },
    table: {
      width: '100%',
      minWidth: 960,
      marginBottom: theme.spacing(3),
    },
    tableHead: {
      backgroundColor: theme.palette.common.gray100,
      height: 40,
    },
    tableRow: {
      cursor: 'pointer',
      borderBottom: theme.customBorders.gray200,
      height: 48,
      '&:hover': {
        backgroundColor: theme.palette.background.default,
      },
      '& > th': {
        padding: theme.spacing(0, 2),
      },
    },
    tableCell: {
      padding: theme.spacing(1, 2),
      borderBottom: 'none',
      '& > svg': {
        fontSize: 'inherit',
      },
      '&:last-child': {
        padding: theme.spacing(1, 2),
      },
    },
    stagesColumn: {
      width: '60%',
    },
    header: {
      marginBottom: theme.spacing(1),
    },
    truncatedText: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': '2',
      '-webkit-box-orient': 'vertical',
      wordBreak: 'break-word',
    },
    reactSelect: {
      menu: {
        zIndex: 1000,
      },
    },
  }),
);
