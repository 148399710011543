import {
  CustomNotificationPreference,
  CustomNotificationPreferenceParams,
  NotificationPreference,
} from 'api-clients/monolith';
import { filter, find, isEmpty, some } from 'lodash';
import React, { useState } from 'react';

import { SelectStage } from './SelectStage';
import { StageOptionType } from './types';
import { createDeletedStageData } from './utils';

export interface SelectWorkflowStageProps {
  notifiableType: string;
  notifiableId: string;
  notificationPreference: NotificationPreference;
  setStagesData: React.Dispatch<
    React.SetStateAction<CustomNotificationPreferenceParams[]>
  >;
}

export const SelectWorkflowStage: React.FC<SelectWorkflowStageProps> = ({
  notifiableType,
  notifiableId,
  notificationPreference,
  setStagesData,
}) => {
  const initialSelectedStages: StageOptionType[] = [];
  if (notificationPreference?.custom_notification_preferences)
    notificationPreference.custom_notification_preferences.forEach(
      preference => {
        if (!preference.funnel_id)
          initialSelectedStages.push({
            label: preference.entity_name,
            value: preference.entity_id,
          });
      },
    );

  const [selectedStages, setSelectedStages] = useState<StageOptionType[]>(
    initialSelectedStages,
  );

  const onChange = (stages: StageOptionType[]) => {
    setSelectedStages(stages);
    const deletedStages = filter(
      notificationPreference.custom_notification_preferences,
      preference => {
        return !some(
          stages,
          (option: StageOptionType) =>
            !preference.funnel_id &&
            option.value === String(preference.entity_id),
        );
      },
    );

    const deletedStagesData = createDeletedStageData(deletedStages);
    const selectedStagesData = stages.map((option: StageOptionType) => {
      const stageId = String(option.value);
      const id = notificationPreference.custom_notification_preferences
        ? find(
            notificationPreference.custom_notification_preferences,
            (preference: CustomNotificationPreference) =>
              preference.entity_id === stageId && isEmpty(preference.funnel_id),
          )?.id
        : undefined;
      return {
        entity_id: stageId,
        entity_type: 'Stage',
        funnel_id: null,
        id,
      };
    });

    setStagesData([...deletedStagesData, ...selectedStagesData]);
  };

  return (
    <SelectStage
      notifiableType={notifiableType}
      notifiableId={notifiableId}
      stageType="workflow"
      selectedStages={selectedStages}
      onChange={onChange}
    />
  );
};
