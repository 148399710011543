import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import { addDefaultErrorMessageAction } from 'containers/FlashMessage/actions';
import { apiGet } from 'utils/axios';

interface SubAccount {
  name: string;
  id: string;
}

export default function useSubAccounts() {
  const dispatch = useDispatch();
  const url = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/sub_accounts`;
  const [data, setData] = useState<SubAccount[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsFetching(true);
      try {
        /* eslint-disable camelcase */
        const res = (await apiGet(url)) as AxiosResponse<{
          sub_accounts: SubAccount[];
        }>;
        setData(res.data.sub_accounts);
        /* eslint-enable camelcase */
      } catch (e) {
        dispatch(addDefaultErrorMessageAction());
      } finally {
        setIsFetching(false);
      }
    };
    void fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data, isFetching };
}
