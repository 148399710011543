import React from 'react';
import { FormattedMessage } from 'react-intl';

import { messages } from './messages';

export const MOVE_TO_NEXT_STAGE = 'RuleStage::Actions::MoveToNextStage';
export const MOVE_TO_STAGE = 'RuleStage::Actions::MoveToStage';
export const MOVE_TO_POSITION = 'RuleStage::Actions::MoveToPosition';
export const DO_NOT_MOVE = 'RuleStage::Actions::DoNotMove';
export const UPDATE_APPLICANT_DATA = 'RuleStage::Actions::UpdateApplicantData';
export const SWITCH_APPLICANT_DATA = 'RuleStage::Actions::SwitchApplicantData';
export const RESET_PARTNER_STATUS = 'RuleStage::Actions::ResetPartnerStatus';
export const INCREMENT_APPLICANT_VALUE =
  'RuleStage::Actions::IncrementApplicantValue';
export const MARK_APPLICANT_DUPLICATE =
  'RuleStage::Actions::MarkApplicantDuplicate';
export const CREATE_OR_UPDATE_WX_WORKER =
  'RuleStage::Actions::CreateOrUpdateWxWorker';
export const RESUME_ONFIDO_REPORTS = 'RuleStage::Actions::ResumeOnfidoReports';
export const RUN_CHECKR_REPORTS = 'RuleStage::Actions::RunCheckrReports';
export const RUN_STERLING_REPORTS = 'RuleStage::Actions::RunSterlingReports';
export const RUN_ACCURATE_REPORTS = 'RuleStage::Actions::RunAccurateReports';

export const stageTypeWithReasons: { [key: string]: boolean } = {
  ArchivedStage: true,
  RejectedStage: true,
};

export const getActionErrorMessage = (
  key: string,
  errors?: {
    [key: string]: string[];
  },
) => {
  if (errors === undefined) {
    return undefined;
  }

  const errorMessage = errors?.[`rule_set.rules.action_set.actions.${key}`] ||
    errors?.[`else_action_set.actions.${key}`] ||
    errors?.[key] || <FormattedMessage {...messages.cantBeBlank} />;

  if (Array.isArray(errorMessage)) {
    return errorMessage.join(', ');
  }

  return errorMessage;
};
