import { WorkflowStageDetail } from 'api-clients/monolith';
import produce from 'immer';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { SettingSwitch } from '../../../SettingSwitch';
import { messages } from '../messages';

// Review Stages and VideoRecording Stages must stay `true`
const NONCONFIGURABLE_STAGES = ['ReviewStage', 'VideoRecordingStage'];

interface AllowRejectInGo {
  stage: WorkflowStageDetail;
  setStage: React.Dispatch<React.SetStateAction<WorkflowStageDetail>>;
}

export const AllowRejectInGo: FC<AllowRejectInGo> = ({ stage, setStage }) => {
  const disabled = NONCONFIGURABLE_STAGES.includes(stage.type);

  const onToggle = () => {
    setStage(
      produce(stage, draftStage => {
        draftStage.allow_reject_in_go = !draftStage.allow_reject_in_go;
      }),
    );
  };

  return (
    <SettingSwitch
      dataKey="allow_reject_in_go"
      checked={Boolean(stage.allow_reject_in_go)}
      onChange={onToggle}
      label={<FormattedMessage {...messages.allowRejectInHireGo} />}
      disabled={disabled}
    />
  );
};
