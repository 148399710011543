import {
  Button,
  IconButton,
  Input,
  StyledReactSelect,
} from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Error as InputError } from 'components/Error';

import { useOptionBanks } from '../../../../containers/WorkflowEditor/hooks/useOptionBanks';
import { createNewDropdownOption } from './helpers';
import { useDropdownDataField } from './hooks';
import { multipleChoiceQuestionFormMessages } from './messages';
import { useDropdownQuestionFormStyles } from './styles';
const {
  addOptionButtonText,
  questionFormOptionChoiceLabel,
  questionFormOptionChoicePlaceholder,
  questionFormOptionDeleteText,
  header,
  optionsRemovedForOptionBankText,
  questionFormLinkOptionBankPlaceholder,
  questionFormLinkOptionBankLabel,
} = multipleChoiceQuestionFormMessages;

export const DropdownForm = () => {
  const intl = useIntl();
  const classes = useDropdownQuestionFormStyles();
  const { values, errors, handleChange, arrayHelpers } = useDropdownDataField();
  const { result } = useOptionBanks();
  const options = Array.isArray(values?.options) ? values.options : [];

  if (result.status !== 'ready') {
    return null;
  }

  const optionBankOptions = result.data.option_banks.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

  return (
    <Grid item xs={12}>
      <Grid
        container
        alignItems="center"
        className={classes.optionsContainer}
        spacing={2}
      >
        <Grid item xs={6}>
          <Typography>
            <FormattedMessage {...header} />
          </Typography>
        </Grid>
        <Grid item xs={6} container justify="flex-end">
          <Button
            type="secondary"
            size="small"
            autoWidth
            disabled={Boolean(values.option_bank_id)}
            onClick={() => {
              handleChange({
                options: arrayHelpers.insertAt(
                  options,
                  createNewDropdownOption(),
                  'end',
                ),
              });
            }}
          >
            <Typography
              variant="body2"
              color="primary"
              className={classes.addOptionButtonText}
            >
              <Add className={classes.addOptionButtonIcon} />
              <FormattedMessage {...addOptionButtonText} />
            </Typography>
          </Button>
        </Grid>
        <Grid xs={12}>
          <ul className={classes.optionList}>
            {options.length === 0 && values.option_bank_id && (
              <Typography
                variant="body2"
                color="primary"
                className={classes.removeOptionLinkText}
              >
                <FormattedMessage {...optionsRemovedForOptionBankText} />
              </Typography>
            )}
            {options.map((opt, idx) => {
              const hasError = Boolean(errors?.options?.[idx]);
              return (
                // eslint-disable-next-line no-underscore-dangle
                <li key={opt._id as string} className={classes.option}>
                  <div className={classes.optionInputContainer}>
                    <Input
                      aria-label={intl.formatMessage(
                        questionFormOptionChoiceLabel,
                      )}
                      placeholder={intl.formatMessage(
                        questionFormOptionChoicePlaceholder,
                      )}
                      value={options[idx].label}
                      onChange={(
                        event: React.ChangeEvent<HTMLTextAreaElement>,
                      ) => {
                        const { value } = event.target;
                        const updateIdx = options.indexOf(opt);

                        handleChange({
                          options: arrayHelpers.updateAt(
                            options,
                            { ...opt, value, label: value },
                            updateIdx,
                          ),
                        });
                      }}
                      error={hasError}
                      required
                    />

                    <IconButton
                      size="small"
                      disabled={options.length === 1}
                      aria-label={intl.formatMessage(
                        questionFormOptionDeleteText,
                      )}
                      onClick={() => {
                        const removeIdx = options.indexOf(opt);
                        handleChange({
                          options: arrayHelpers.removeAt(options, removeIdx),
                        });
                      }}
                    >
                      <Delete fontSize="small" />
                    </IconButton>
                  </div>
                  <div
                    className={classNames(classes.optionInputErrorContainer, {
                      [classes.optionInputErrorContainerHeight]:
                        Boolean(hasError),
                    })}
                  >
                    <InputError error={errors?.options?.[idx]} align="right" />
                  </div>
                </li>
              );
            })}
          </ul>
          {result.data.option_banks.length > 0 && (
            <>
              <div role="presentation" className={classes.divider}>
                <span className={classes.dividerCopy}>OR</span>
              </div>
              <div className={classes.optionBankLinkSelectContainer}>
                <div className={classes.optionBankLinkSelectChildContainer}>
                  <StyledReactSelect
                    label={intl.formatMessage(questionFormLinkOptionBankLabel)}
                    placeholder={intl.formatMessage(
                      questionFormLinkOptionBankPlaceholder,
                    )}
                    isClearable
                    defaultValue={
                      optionBankOptions.find(
                        opt => opt.value === values.option_bank_id,
                      ) ?? null
                    }
                    onChange={(
                      opt: (typeof optionBankOptions)[number] | null,
                      { action },
                    ) => {
                      const value: number | null | undefined =
                        action === 'clear' ? null : opt?.value;

                      handleChange({
                        options:
                          action === 'clear' ? [createNewDropdownOption()] : [],
                        option_bank_id: value,
                      });
                    }}
                    options={optionBankOptions}
                  />
                </div>
              </div>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
