import { makeStyles } from '@material-ui/core';
import { startCase, toLower } from 'lodash';
import React, { VFC } from 'react';
import { useSelector } from 'react-redux';

import { makeSelectWhoami } from 'containers/Auth_old/selectors';

import { HistoryItem } from '../HistoryAddressItem';

const useStyles = makeStyles(theme => ({
  historyItem: {
    paddingLeft: theme.spacing(1),
  },
}));

interface HistoryEmploymentItemProps {
  details: HistoryItem;
}

export const HistoryEmploymentItem: VFC<HistoryEmploymentItemProps> = ({
  details,
}) => {
  const classes = useStyles();
  const whoami = useSelector(makeSelectWhoami());

  const renderFieldData = (key: string, details: HistoryItem) => {
    let value = details[key];

    switch (key) {
      case 'employer_address': {
        /* eslint-disable camelcase */
        const { city, country_code, state_code } = details;
        return <>{[city, state_code, country_code].join(', ')}</>;
        /* eslint-enable camelcase */
      }
      case 'documents': {
        value = details.files;
        return Array.isArray(value) ? (
          <span>
            <ul>
              {value.map((value: string, index: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={`${key}-document-${index}`}>
                  <a href={value} target="_blank">
                    Download file{index + 1}
                  </a>
                </li>
              ))}
            </ul>
          </span>
        ) : (
          '-'
        );
      }
      default: {
        return value ?? '-';
      }
    }
  };

  const additionalFieldsToRender = whoami.feature_flags[
    'partner-employment-history-additional-questions'
  ]
    ? [
        'employee_code',
        'designation',
        'ctc',
        'reason_for_leaving',
        'rm_name',
        'rm_designation',
        'rm_organization',
        'rm_contact',
      ]
    : [];

  const fieldsToRender = [
    'employer_name',
    'employer_contact_name',
    'employer_address',
    'job_title',
    ...additionalFieldsToRender,
    'documents',
  ];

  return (
    <ul className={classes.historyItem}>
      {fieldsToRender.map(field => (
        <li key={field}>
          <span>
            {startCase(toLower(field))}: {renderFieldData(field, details)}
          </span>
        </li>
      ))}
    </ul>
  );
};
