import {
  Button,
  Input,
  Loader,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Radio,
  StyledReactSelect,
} from '@fountain/fountain-ui-components';
import { RadioGroup, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {
  ApproversObj,
  CancelablePromise,
  OpeningApprovalsService,
  OpeningApproverGroupObj,
  OpeningApproverGroupParams,
} from 'api-clients/monolith';
import { useApiService } from 'hooks';
import React, { useCallback, useEffect, useRef, useState, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { FilterLocationDropdown } from 'components/FilterDropdown/FilterLocationDropdown';
import { Filterable } from 'components/FilterDropdown/util';
import { makeSelectOpeningApprovalsImprovementsEnabled } from 'containers/Auth_old/selectors';
import { addMessageAction } from 'containers/FlashMessage/actions';
import { useApiServiceMutation } from 'hooks/useApiServiceMutation';
import globalMessages from 'shared/global/messages';

import { ApproversDropdown } from './ApproversDropdown';
import messages from './messages';
import { useStyles } from './styles';
import { AppliedToType, AppliedToTypes } from './types';

export interface ApproversModalProps {
  refreshApproversData: () => void;
  isOpen: boolean;
  onClose: () => void;
  selectedApproverGroupId?: string;
}

export const ApproverGroupsModal: VFC<ApproversModalProps> = ({
  refreshApproversData,
  isOpen,
  onClose,
  selectedApproverGroupId,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const openingApprovalsImprovementsEnabled = useSelector(
    makeSelectOpeningApprovalsImprovementsEnabled(),
  );

  const [approverGroupName, setApproverGroupName] = useState<string | null>(
    null,
  );
  const [selectedApprovers, setSelectedApprovers] = useState<ApproversObj[]>(
    [],
  );
  const [appliedTo, setAppliedTo] = useState<AppliedToType>(
    AppliedToTypes.ALL_OPENINGS,
  );
  const [locations, setLocations] = useState<Filterable[]>([]);
  const fetched = useRef<string | null>(null);

  const { mutation: fetchApproverGroup, result: selectedApproverGroupResult } =
    useApiServiceMutation<
      OpeningApproverGroupObj,
      (approverGroupId: string) => CancelablePromise<OpeningApproverGroupObj>
    >(
      // eslint-disable-next-line @typescript-eslint/unbound-method
      OpeningApprovalsService.getInternalApiOpeningApprovalApproverGroups1,
      {
        onSuccess: (data: OpeningApproverGroupObj) => {
          const { approver_group: approverGroup } = data;
          setApproverGroupName(approverGroup.name);
          setSelectedApprovers(approverGroup.approvers ?? []);
          if (openingApprovalsImprovementsEnabled) {
            setAppliedTo(
              approverGroup.applied_to ?? AppliedToTypes.ALL_OPENINGS,
            );
            setLocations(
              (approverGroup.locations as { id: string; name: string }[])?.map(
                location => ({
                  id: location.id,
                  title: location.name,
                  name: 'location',
                }),
              ) ?? [],
            );
          }
        },
      },
    );

  useEffect(() => {
    if (
      selectedApproverGroupId &&
      fetched.current !== selectedApproverGroupId
    ) {
      fetched.current = selectedApproverGroupId;
      fetchApproverGroup(selectedApproverGroupId);
    }
  }, [selectedApproverGroupId, fetchApproverGroup, fetched]);

  const { result: approversResult } = useApiService(
    useCallback(
      () =>
        OpeningApprovalsService.getInternalApiOpeningApprovalApproverGroupsGetApprovers(
          selectedApproverGroupId,
        ),
      [selectedApproverGroupId],
    ),
  );

  const handleSuccess = (message: string) => {
    onClose();
    refreshApproversData();
    dispatch(addMessageAction(message, 'success'));
  };

  const { mutation: createApproverGroup } = useApiServiceMutation<
    OpeningApproverGroupParams,
    (
      requestBody: OpeningApproverGroupParams,
    ) => CancelablePromise<OpeningApproverGroupParams>,
    { message: string }
  >(
    // eslint-disable-next-line @typescript-eslint/unbound-method
    OpeningApprovalsService.postInternalApiOpeningApprovalApproverGroups,
    {
      onSuccess: () => {
        handleSuccess(intl.formatMessage(messages.createSuccessMessage));
      },
      onError: errorBody => {
        const errorMessages =
          errorBody?.message ?? 'Unable to process the request.';
        dispatch(addMessageAction(errorMessages, 'error'));
      },
    },
  );

  const { mutation: updateApproverGroup } = useApiServiceMutation<
    OpeningApproverGroupParams,
    (
      id: string,
      requestBody: OpeningApproverGroupParams,
    ) => CancelablePromise<OpeningApproverGroupParams>,
    { message: string }
  >(
    // eslint-disable-next-line @typescript-eslint/unbound-method
    OpeningApprovalsService.patchInternalApiOpeningApprovalApproverGroups,
    {
      onSuccess: () => {
        handleSuccess(intl.formatMessage(messages.updateSuccessMessage));
      },
      onError: errorBody => {
        const errorMessages =
          errorBody?.message ?? 'Unable to process the request.';
        dispatch(addMessageAction(errorMessages, 'error'));
      },
    },
  );

  const onSave = () => {
    if (selectedApprovers.length > 4) {
      dispatch(
        addMessageAction(
          intl.formatMessage(messages.maxApproversErrorMessage),
          'error',
          false,
        ),
      );
      return;
    }

    if (!approverGroupName) {
      dispatch(
        addMessageAction(
          intl.formatMessage(messages.groupNameErrorMessage),
          'error',
          false,
        ),
      );
      return;
    }

    if (
      appliedTo === AppliedToTypes.SELECTED_LOCATIONS &&
      locations.length === 0
    ) {
      dispatch(
        addMessageAction(
          intl.formatMessage(messages.emptyOpeningsErrorMessage),
          'error',
          false,
        ),
      );
      return;
    }

    const selectedApproverIds = selectedApprovers.map(item => item.id);

    const removedApprovers =
      selectedApproverGroupResult.status !== 'ready'
        ? []
        : selectedApproverGroupResult.data.approver_group.approvers.reduce(
            (acc: string[], item) => {
              if (!selectedApproverIds.includes(item.id)) acc.push(item.id);
              return acc;
            },
            [],
          );

    const selectedLocationIds = locations.map(location => location.id);
    const appliedToValue = openingApprovalsImprovementsEnabled
      ? appliedTo
      : undefined;

    if (selectedApproverGroupId) {
      updateApproverGroup(selectedApproverGroupId, {
        opening_approver_group: {
          name: approverGroupName,
          add_users: selectedApproverIds,
          remove_users: removedApprovers,
          applied_to: appliedToValue,
          location_ids: selectedLocationIds,
        },
      });
    } else {
      createApproverGroup({
        opening_approver_group: {
          name: approverGroupName,
          add_users: selectedApproverIds,
          remove_users: removedApprovers,
          applied_to: appliedToValue,
          location_ids: selectedLocationIds,
        },
      });
    }
  };
  const handleSelectUser = (approvers: ApproversObj[] | null) => {
    setSelectedApprovers(approvers ?? []);
  };

  const handleGroupNameChange = (e: { target: { value: string } }) => {
    setApproverGroupName(e.target.value);
  };

  const handleAppliedToChange = (e: { target: { value: string } }) => {
    const newValue = e.target.value as AppliedToType;

    setAppliedTo(newValue);
    if (newValue === AppliedToTypes.ALL_OPENINGS) {
      setLocations([]);
    }
  };

  if (approversResult.isLoading || selectedApproverGroupResult.isLoading) {
    // TODO: Implement error state
  }

  if (approversResult.isLoading || selectedApproverGroupResult.isLoading)
    return <Loader fullScreen size="1.5rem" />;

  if (approversResult.status === 'ready') {
    const { locations: coveredLocations } = approversResult.data;

    const showWarning =
      coveredLocations === 'ALL_OPENINGS' ||
      coveredLocations.some(
        location =>
          appliedTo === AppliedToTypes.ALL_OPENINGS ||
          locations.find(filterable => filterable.id === location),
      );

    return (
      <Modal
        maxWidth="600px"
        ariaLabelledBy={<FormattedMessage {...messages.createApprovalRule} />}
        disableBackdropClick
        fullScreenOnMobile
        onClose={onClose}
        open={isOpen}
        disableEnforceFocus
      >
        <ModalHeader
          ariaLabelledBy="<FormattedMessage {...messages.createApprovalRule} />"
          onClose={onClose}
          showIcon={false}
        >
          {intl.formatMessage(
            selectedApproverGroupId
              ? messages.editApprovalRule
              : messages.createApprovalRule,
          )}
        </ModalHeader>
        <div className={styles.tallerModal}>
          <ModalContent dividers>
            <div className={styles.modalContent}>
              <Typography variant="body2" className={styles.info}>
                <FormattedMessage
                  {...(selectedApproverGroupId
                    ? messages.approversModalEditSubtitle
                    : messages.approversModalSubtitle)}
                />
              </Typography>

              <div className={styles.inputContent}>
                <Typography variant="body2">
                  <FormattedMessage {...messages.ruleNameLabel} />
                </Typography>

                <Input
                  fullWidth
                  onChange={handleGroupNameChange}
                  value={approverGroupName}
                  placeholder={intl.formatMessage(
                    messages.inputPlaceholderText,
                  )}
                  data-testid="template-name"
                />
              </div>

              <div className={styles.inputContent}>
                <Typography variant="body2">
                  <FormattedMessage {...messages.approvalRuleUsersLabel} />
                </Typography>

                {openingApprovalsImprovementsEnabled ? (
                  <ApproversDropdown
                    approvers={approversResult.data.approvers}
                    selectedApprovers={selectedApprovers}
                    onChange={handleSelectUser}
                  />
                ) : (
                  <StyledReactSelect
                    isMulti
                    canSelectAll={false}
                    isSearchable
                    options={approversResult.data.approvers}
                    onChange={handleSelectUser}
                    placeholder={intl.formatMessage(
                      messages.selectPlaceolderText,
                    )}
                    getOptionLabel={(option: ApproversObj) => option.name}
                    getOptionValue={(option: ApproversObj) => option.id}
                    value={selectedApprovers}
                  />
                )}

                <Typography variant="body2">
                  <FormattedMessage {...messages.approversModalSelectInfo} />
                </Typography>
              </div>

              {openingApprovalsImprovementsEnabled && (
                <>
                  <div className={styles.inputContent}>
                    <Typography variant="body2">
                      <FormattedMessage {...messages.requireApprovalForLabel} />
                    </Typography>

                    <RadioGroup
                      aria-label="applied-to"
                      name="applied-to"
                      value={appliedTo}
                      className={styles.modalRadioGroup}
                      onChange={handleAppliedToChange}
                    >
                      <Radio
                        key={AppliedToTypes.ALL_OPENINGS}
                        value={AppliedToTypes.ALL_OPENINGS}
                        label={intl.formatMessage(messages.allOpenings)}
                      />
                      <Radio
                        key={AppliedToTypes.SELECTED_LOCATIONS}
                        value={AppliedToTypes.SELECTED_LOCATIONS}
                        label={intl.formatMessage(
                          messages.specifiedOpeningsRadioLabel,
                        )}
                      />
                    </RadioGroup>
                  </div>
                  {appliedTo === AppliedToTypes.SELECTED_LOCATIONS && (
                    <div className={styles.inputContent}>
                      <Typography variant="body2">
                        <FormattedMessage
                          {...messages.locationsThatRequireOpeningApprovalLabel}
                        />
                      </Typography>

                      <div className={styles.modalDropdownContainer}>
                        <FilterLocationDropdown
                          selected={locations}
                          setSelected={setLocations}
                          showTitle
                        />
                      </div>
                    </div>
                  )}
                  {showWarning && (
                    <Alert severity="warning">
                      <Typography variant="body2" className={styles.cheeto}>
                        <FormattedMessage {...messages.overlappingRule} />
                      </Typography>
                    </Alert>
                  )}
                </>
              )}
            </div>
          </ModalContent>
        </div>
        <ModalFooter>
          <Button
            aria-label={intl.formatMessage(globalMessages.cancel)}
            onClick={onClose}
            type="secondary"
            autoWidth
            className={styles.modalButton}
          >
            {intl.formatMessage(globalMessages.cancel)}
          </Button>
          <Button
            aria-label={intl.formatMessage(globalMessages.save)}
            onClick={onSave}
            disabled={
              !approverGroupName ||
              selectedApprovers.length === 0 ||
              (appliedTo === AppliedToTypes.SELECTED_LOCATIONS &&
                locations.length === 0)
            }
            autoWidth
            className={styles.modalButton}
          >
            {intl.formatMessage(globalMessages.save)}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  return null;
};
