/**
 *
 * BackButton Component
 *
 */

import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import PropTypes from 'prop-types';
import React from 'react';
import { classNames } from 'react-extras';
import { useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';

import globalMessages from 'shared/global/messages';

import styles from './styles';

const handleOnClick = (event, redirectUrl, onClick, history) => {
  if (!redirectUrl) {
    event.preventDefault();
    if (onClick) {
      onClick();
    } else {
      history.goBack();
    }
  }
};

const BackButton = props => {
  const intl = useIntl();
  const history = useHistory();
  const {
    classes,
    text = intl.formatMessage(globalMessages.back),
    redirectUrl,
    absolute,
    className,
    onClick,
  } = props;
  return (
    <Typography
      variant="caption"
      className={classNames(classes.backButton, className, {
        absolute,
      })}
      component={Link}
      onClick={e => handleOnClick(e, redirectUrl, onClick, history)}
      to={redirectUrl}
    >
      <KeyboardBackspaceIcon className={classes.backButtonIcon} />
      {text}
    </Typography>
  );
};

BackButton.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string,
  redirectUrl: PropTypes.string,
  absolute: PropTypes.bool,
  className: PropTypes.string,
  onClick: () => {},
};

BackButton.defaultProps = {
  absolute: false,
};

export default withStyles(styles)(BackButton);
