import { makeStyles, Theme } from '@material-ui/core/styles';

const blueChip = (theme: Theme) => ({
  alignItems: 'center',
  borderRadius: theme.spacing(1),
  padding: theme.spacing(0.75, 1, 0.75, 1.5),
  '& > p': {
    fontSize: theme.typography.caption.fontSize,
  },
});

const modalDropdown = (theme: Theme) => ({
  '& > div': {
    width: '100%',
    marginTop: theme.spacing(0.5),
    position: 'relative',
    '& > div': {
      flexWrap: 'wrap',
      gap: theme.spacing(1),
      paddingRight: theme.spacing(5),
      '& > div:last-child': {
        '& > img': {
          position: 'absolute',
          top: '50%',
          right: '15px',
        },
      },
      '& > div': {
        ...blueChip(theme),
      },
    },
  },
});

export const useStyles = makeStyles(theme => ({
  info: {
    fontWeight: 450,
    lineHeight: '20px',
    color: theme.palette.common.gray800,
  },
  tallerModal: {
    '& > div': {
      maxHeight: '64vh',
    },
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  inputContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  table: {
    width: '100%',
    marginBottom: theme.spacing(3),
  },
  tableHead: {
    backgroundColor: theme.palette.common.gray100,
    height: 40,
  },
  tableRow: {
    cursor: 'pointer',
    borderBottom: theme.customBorders.gray200,
    height: 48,
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
    '& > th': {
      padding: theme.spacing(0, 2),
    },
  },
  tableCell: {
    padding: theme.spacing(1, 2),
    borderBottom: 'none',
    '& > svg': {
      fontSize: 'inherit',
    },
    '&:last-child': {
      padding: theme.spacing(1, 2),
    },
  },
  cellContent: {
    fontSize: theme.typography.caption.fontSize,
  },
  alertContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
    display: 'inline-block',
  },
  exceptionContainer: {
    height: '70%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalButton: {
    padding: theme.spacing(1.5, 3),
    marginLeft: theme.spacing(2),
  },
  modalRadioGroup: {
    flexDirection: 'row',
    gap: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  blueChip: {
    ...blueChip(theme),
    background: 'var(--blue-50, #EDF6FF)',
    color: theme.palette.common.blue500,
  },
  modalDropdownContainer: {
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      ...modalDropdown(theme),
    },
  },
  modalDropdown: modalDropdown(theme),
  cheeto: {
    color: theme.palette.common.orange800,
    fontWeight: 500,
  },
}));
