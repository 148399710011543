import { defineMessages } from 'react-intl';

export const multipleChoiceQuestionFormMessages = defineMessages({
  addOptionButtonText: {
    id: `app.components.FormBuilder.QuestionTypeForms.MultipleChoice.addOptionButtonText`,
    defaultMessage: 'Add option',
  },
  questionFormOptionChoiceLabel: {
    id: `app.components.FormBuilder.QuestionTypeForms.MultipleChoice.questionFormOptionChoiceLabel`,
    defaultMessage: 'choice text',
  },
  questionFormOptionChoicePlaceholder: {
    id: `app.components.FormBuilder.QuestionTypeForms.MultipleChoice.questionFormOptionChoicePlaceholder`,
    defaultMessage: 'Choice',
  },
  questionFormOptionDeleteText: {
    id: `app.components.FormBuilder.QuestionTypeForms.MultipleChoice.questionFormOptionDeleteText`,
    defaultMessage: 'delete option',
  },
  header: {
    id: `app.components.FormBuilder.QuestionTypeForms.MultipleChoice.header`,
    defaultMessage: 'Options',
  },
  optionsRemovedForOptionBankText: {
    id: `app.components.FormBuilder.QuestionTypeForms.MultipleChoice.optionsRemovedForOptionBankText`,
    defaultMessage:
      'Options removed due to option bank linking, please remove link to add options',
  },
  questionFormLinkOptionBankPlaceholder: {
    id: `app.components.FormBuilder.QuestionTypeForms.MultipleChoice.questionFormLinkOptionBankPlaceholder`,
    defaultMessage: 'Select Option Bank',
  },
  questionFormLinkOptionBankLabel: {
    id: `app.components.FormBuilder.QuestionTypeForms.MultipleChoice.questionFormLinkOptionBankLabel`,
    defaultMessage: 'Link an option bank',
  },
});
