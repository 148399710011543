/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Concept } from '../models/Concept';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ConceptsService {

    /**
     * Requests Concepts
     * Returns all concepts
     * @param page page number in result set
     * @param query concept name
     * @param sortBy field to sort results by
     * @param sortDirection direction to sort results
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiConcepts(
        page?: number,
        query?: string,
        sortBy?: string,
        sortDirection?: 'asc' | 'desc',
    ): CancelablePromise<{
        concepts: Array<Concept>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/concepts',
            query: {
                'page': page,
                'query': query,
                'sort_by': sortBy,
                'sort_direction': sortDirection,
            },
        });
    }

}