/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmailMessageContent } from '../models/EmailMessageContent';
import type { Emails } from '../models/Emails';
import type { HiringGoal } from '../models/HiringGoal';
import type { HiringGoalBulkCreateUpdateResponse } from '../models/HiringGoalBulkCreateUpdateResponse';
import type { InitiateTelephoneCallResponse } from '../models/InitiateTelephoneCallResponse';
import type { LocationGroup } from '../models/LocationGroup';
import type { Opening } from '../models/Opening';
import type { Pagination } from '../models/Pagination';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApiSelfServeV2Service {

    /**
     * index
     * Returns list of email conversations
     * @param externalId Applicant's external_id
     * @returns Emails success
     * @throws ApiError
     */
    public static getApiSelfServeV2ApplicantsEmailConversations(
        externalId: string,
    ): CancelablePromise<Emails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api_self_serve/v2/applicants/{external_id}/email_conversations',
            path: {
                'external_id': externalId,
            },
        });
    }

    /**
     * email_content
     * Returns email content
     * @param externalId Applicant's external_id
     * @param emailMessageExternalId EmailMessage external_id
     * @returns EmailMessageContent success
     * @throws ApiError
     */
    public static getApiSelfServeV2ApplicantsEmailMessagesEmailContent(
        externalId: string,
        emailMessageExternalId: string,
    ): CancelablePromise<EmailMessageContent> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api_self_serve/v2/applicants/{external_id}/email_messages/{email_message_external_id}/email_content',
            path: {
                'external_id': externalId,
                'email_message_external_id': emailMessageExternalId,
            },
        });
    }

    /**
     * initiate_call
     * Starts a call with the applicant
     * @param externalId Applicant's external_id
     * @returns InitiateTelephoneCallResponse success
     * @throws ApiError
     */
    public static postApiSelfServeV2ApplicantsTelephonyInitiateCall(
        externalId: string,
    ): CancelablePromise<InitiateTelephoneCallResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api_self_serve/v2/applicants/{external_id}/telephony/initiate_call',
            path: {
                'external_id': externalId,
            },
            errors: {
                422: `unprocessable`,
            },
        });
    }

    /**
     * resubmit_partner_data
     * Re-sends partner data for a specified applicant
     * @param externalId External ID for the applicant
     * @param requestBody Partner information
     * @returns any success
     * @throws ApiError
     */
    public static postApiSelfServeV2ApplicantsResubmitPartnerData(
        externalId: string,
        requestBody: {
            partner_id?: string;
            partner_stage_id?: string;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api_self_serve/v2/applicants/{external_id}/resubmit_partner_data',
            path: {
                'external_id': externalId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Unauthorized`,
            },
        });
    }

    /**
     * index
     * Returns Hiring Goals
     * @param openingId filter query for opening_id
     * @param open filter query for open scope
     * @param page pagination setting for page to view
     * @param perPage pagination setting for max number of records to return
     * @param current filter query for current scope
     * @param past filter query for past scope
     * @param sortBy filter query for sort_by scope
     * @param sortDirection filter query for sort_direction scope
     * @returns any success
     * @throws ApiError
     */
    public static getApiSelfServeV2BulkHiringGoals(
        openingId: string,
        open: string,
        page: string,
        perPage: string,
        current?: string,
        past?: string,
        sortBy?: string,
        sortDirection?: string,
    ): CancelablePromise<{
        hiring_goals: Array<HiringGoal>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api_self_serve/v2/bulk_hiring_goals',
            query: {
                'opening_id': openingId,
                'open': open,
                'current': current,
                'past': past,
                'page': page,
                'per_page': perPage,
                'sort_by': sortBy,
                'sort_direction': sortDirection,
            },
        });
    }

    /**
     * create_or_update
     * Bulk Creates or Updates Hiring Goals
     * @param requestBody Hiring Goals
     * @returns HiringGoalBulkCreateUpdateResponse success
     * @throws ApiError
     */
    public static postApiSelfServeV2BulkHiringGoalsCreateOrUpdate(
        requestBody: {
            goals: Array<{
                goal: number;
                hire_by?: string;
                start_date?: string;
                automatically_close_on_date?: string;
                funnel_id?: string;
                external_id?: string;
                form_index?: number;
            }>;
            funnel_id: string;
        },
    ): CancelablePromise<HiringGoalBulkCreateUpdateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api_self_serve/v2/bulk_hiring_goals/create_or_update',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * destroy
     * Closes or Soft Deletes a Hiring Goal
     * @param externalId Hiring Goal External ID
     * @returns void
     * @throws ApiError
     */
    public static deleteApiSelfServeV2BulkHiringGoals(
        externalId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api_self_serve/v2/bulk_hiring_goals/{external_id}',
            path: {
                'external_id': externalId,
            },
            errors: {
                401: `unauthorized`,
                422: `unprocessable`,
            },
        });
    }

    /**
     * index
     * Returns a list of data keys or full data key objects if get_data_key_objects is true
     * @param getDataKeyWithOptions If true, returns full data key objects instead of just keys
     * @param getDataKeyObjects If true, returns full data key objects instead of just keys
     * @returns any success
     * @throws ApiError
     */
    public static getApiSelfServeV2DataKeys(
        getDataKeyWithOptions?: boolean,
        getDataKeyObjects?: boolean,
    ): CancelablePromise<{
        data_keys: Array<(string | {
            id?: number;
            key?: string;
            created_at?: string;
            concept_id?: string | null;
            key_type?: string;
            restricted?: boolean;
            storage?: string;
            title?: string;
        } | {
            id?: number;
            key?: string;
            created_at?: string;
            concept_id?: string | null;
            key_type?: string;
            restricted?: boolean;
            storage?: string;
            title?: string;
            possible_values?: Array<string>;
        })>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api_self_serve/v2/data_keys',
            query: {
                'get_data_key_with_options': getDataKeyWithOptions,
                'get_data_key_objects': getDataKeyObjects,
            },
        });
    }

    /**
     * update
     * Updates a specific data key
     * @param id Data key's ID
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static putApiSelfServeV2DataKeys(
        id: number,
        requestBody?: {
            concept_id?: string | null;
            concept_option_mappings?: Record<string, string>;
        },
    ): CancelablePromise<{
        message: string;
        data_key: {
            id?: number;
            key?: string;
            concept_id?: string | null;
        };
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api_self_serve/v2/data_keys/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * index
     * Returns location groups
     * @param query Filter location groups returned by matching title
     * @param userId Filters by user accessible funnels (requires enterprise=true)
     * @returns any success
     * @throws ApiError
     */
    public static getApiSelfServeV2LocationGroups(
        query?: string,
        userId?: string,
    ): CancelablePromise<{
        location_groups: Array<LocationGroup>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api_self_serve/v2/location_groups',
            query: {
                'query': query,
                'user_id': userId,
            },
        });
    }

    /**
     * show
     * Returns a location group
     * @param id Location group's external_id
     * @returns any success
     * @throws ApiError
     */
    public static getApiSelfServeV2LocationGroups1(
        id: string,
    ): CancelablePromise<{
        location_group: LocationGroup;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api_self_serve/v2/location_groups/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * index
     * Returns Openings and Pagination
     * @param workflowId Determines which workflows to return openings from
     * @param title Filter openings returned by matching title
     * @param page Determines which page to return
     * @param perPage number of results to return per page
     * @param sortBy field to sort results by
     * @param sortDirection direction to sort results
     * @param active Determines whether to return active or inactive openings
     * @param query Filter openings returned by matching opening/location/position/brand names
     * @param locationIds filters openings with given location ids
     * @param positionIds filters openings with given position ids
     * @returns any success
     * @throws ApiError
     */
    public static getApiSelfServeV2Openings(
        workflowId?: string,
        title?: string,
        page?: number,
        perPage?: number,
        sortBy?: string,
        sortDirection?: 'asc' | 'desc',
        active?: string,
        query?: string,
        locationIds?: Array<string>,
        positionIds?: Array<string>,
    ): CancelablePromise<{
        openings: Array<Opening>;
        pagination: Pagination;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api_self_serve/v2/openings',
            query: {
                'workflow_id': workflowId,
                'title': title,
                'page': page,
                'per_page': perPage,
                'sort_by': sortBy,
                'sort_direction': sortDirection,
                'active': active,
                'query': query,
                'location_ids': locationIds,
                'position_ids': positionIds,
            },
        });
    }

    /**
     * show
     * Returns a single opening JSON representation
     * @param externalId Opening#external_id
     * @returns any success
     * @throws ApiError
     */
    public static getApiSelfServeV2Openings1(
        externalId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api_self_serve/v2/openings/{external_id}',
            path: {
                'external_id': externalId,
            },
        });
    }

    /**
     * feature flag values for user
     * Returns an object with feature flag values for the specified user.
     * @param id User's external_id
     * @returns any successful
     * @throws ApiError
     */
    public static getApiSelfServeV2UsersFeatures(
        id: string,
    ): CancelablePromise<{
        features: any;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api_self_serve/v2/users/{id}/features',
            path: {
                'id': id,
            },
        });
    }

}