/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NotificationPreference } from '../models/NotificationPreference';
import type { NotificationPreferences } from '../models/NotificationPreferences';
import type { NotificationPreferenceStages } from '../models/NotificationPreferenceStages';
import type { UpdateCustomNotificationPreferenceParams } from '../models/UpdateCustomNotificationPreferenceParams';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserNotificationPreferencesService {

    /**
     * list notification preferences
     * @param notifiableType User roleor User
     * @param notifiableId User role id
     * @returns NotificationPreferences successful
     * @throws ApiError
     */
    public static getInternalApiNotificationPreferences(
        notifiableType: string,
        notifiableId: string,
    ): CancelablePromise<NotificationPreferences> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/{notifiable_type}/{notifiable_id}/notification_preferences',
            path: {
                'notifiable_type': notifiableType,
                'notifiable_id': notifiableId,
            },
        });
    }

    /**
     * update notification preferences
     * @param notifiableType User roleor User
     * @param notifiableId User role id
     * @param notificationKey Notification key
     * @param enabled If true flag is enaabled
     * @returns NotificationPreference successful
     * @throws ApiError
     */
    public static putInternalApiNotificationPreferences(
        notifiableType: string,
        notifiableId: string,
        notificationKey: string,
        enabled: boolean,
    ): CancelablePromise<NotificationPreference> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/internal_api/{notifiable_type}/{notifiable_id}/notification_preferences/{notification_key}',
            path: {
                'notifiable_type': notifiableType,
                'notifiable_id': notifiableId,
                'notification_key': notificationKey,
            },
            query: {
                'enabled': enabled,
            },
        });
    }

    /**
     * update custom notification preferences
     * @param notifiableType User roleor User
     * @param notifiableId User role id
     * @param notificationKey Notification key
     * @param requestBody Update Custom notification preferences
     * @returns NotificationPreference successful
     * @throws ApiError
     */
    public static putInternalApiNotificationPreferencesUpdateCustomPreferences(
        notifiableType: string,
        notifiableId: string,
        notificationKey: string,
        requestBody: UpdateCustomNotificationPreferenceParams,
    ): CancelablePromise<NotificationPreference> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/internal_api/{notifiable_type}/{notifiable_id}/notification_preferences/{notification_key}/update_custom_preferences',
            path: {
                'notifiable_type': notifiableType,
                'notifiable_id': notifiableId,
                'notification_key': notificationKey,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * list stages
     * @param notifiableType User roleor User
     * @param notifiableId User role id
     * @param stageType Stage type
     * @param query Filter openings/stages groups returned by matching title
     * @param page The page of results to view
     * @param perPage The number of results to return per page
     * @returns NotificationPreferenceStages successful
     * @throws ApiError
     */
    public static getInternalApiNotificationPreferencesStages(
        notifiableType: string,
        notifiableId: string,
        stageType: string,
        query?: string,
        page?: number,
        perPage?: number,
    ): CancelablePromise<NotificationPreferenceStages> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/{notifiable_type}/{notifiable_id}/notification_preferences/stages',
            path: {
                'notifiable_type': notifiableType,
                'notifiable_id': notifiableId,
            },
            query: {
                'stage_type': stageType,
                'query': query,
                'page': page,
                'per_page': perPage,
            },
        });
    }

}