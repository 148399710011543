import uniqueId from 'lodash/uniqueId';

import {
  DataField,
  DropdownDataField,
  ReviewStageDataFieldTypeEnum,
} from 'components/FormBuilder/schemas';

import {
  createDefaultCommonField,
  CreateDefaultCommonFieldArgs,
} from '../CommonQuestions/helpers';

export const createNewDropdownOption =
  (): DropdownDataField['options'][number] => ({
    _id: uniqueId(),
    label: '',
    value: '',
    visible: true,
  });

export const createDefaultDropdownField = (
  args: CreateDefaultCommonFieldArgs,
): DropdownDataField => ({
  type: ReviewStageDataFieldTypeEnum.dropdown,
  ...createDefaultCommonField(args),
  options: [createNewDropdownOption()],
  option_bank_id: null,
});

export function isDropdownDataField(
  dataField: DataField,
): dataField is DropdownDataField {
  return dataField.type === ReviewStageDataFieldTypeEnum.dropdown;
}
