/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OptionBanksIndexResponse } from '../models/OptionBanksIndexResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OptionBanksService {

    /**
     * account option banks
     * Get option banks for account
     * @returns OptionBanksIndexResponse successful
     * @throws ApiError
     */
    public static getInternalApiOptionBanksOptionBanks(): CancelablePromise<OptionBanksIndexResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/option_banks/option_banks',
            errors: {
                401: `unauthorized`,
            },
        });
    }

}