import { Input, StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import { snakeCase } from 'lodash';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { Error } from 'components/Error';
import {
  useFormBuilderContextState,
  useQuestionDrawerContextState,
} from 'components/FormBuilder/context';
import { useWorkflowReviewStage } from 'components/FormBuilder/hooks';

import { ReviewStageDataFieldTypeEnum } from '../../schemas';
import { createDefaultDropdownField } from '../Dropdown/helpers';
import { createDefaultRadioField } from '../MultipleChoice/helpers';
import {
  ALL_GROUPED_QUESTIONS,
  GroupedQuestionOption,
  GroupedQuestionsValues,
  HIRING_MANAGER_PAY_RATE_KEY,
  STANDARD_QUESTION_VALUES,
} from './constants';
import {
  createDefaultDatePickerField,
  createDefaultPayRateField,
  createDefaultTextField,
  getGroupedQuestionOptions,
} from './helpers';
import { commonQuestionFormMessages } from './messages';

const {
  questionFormQuestionTypeLabel,
  questionFormQuestionLabel,
  questionFormQuestionHintLabel,
  questionFormQuestionDataKeyLabel,
} = commonQuestionFormMessages;

type CreateDefaultFns =
  | typeof createDefaultRadioField
  | typeof createDefaultTextField
  | typeof createDefaultPayRateField
  | typeof createDefaultDatePickerField
  | typeof createDefaultDropdownField;

const TYPE_TO_DEFAULT: Record<
  keyof typeof ReviewStageDataFieldTypeEnum,
  CreateDefaultFns
> = {
  radio: createDefaultRadioField,
  text_field: createDefaultTextField,
  datepicker: createDefaultDatePickerField,
  dropdown: createDefaultDropdownField,
};

export const CommonQuestionsForm = () => {
  const intl = useIntl();
  const { activeDataEntries } = useWorkflowReviewStage();
  const { values, errors, handleChange } = useFormBuilderContextState();
  const { isEdit } = useQuestionDrawerContextState();
  // we maintain this value outside of the form bc it is dependant on potentially standard quetions
  // which under the hood are just text_field etc but represent a different value in the select
  // it is thrown away and not sent to BE
  const [questionType, setQuestionType] =
    useState<GroupedQuestionsValues | null>(() => {
      if (isEdit) {
        const field = activeDataEntries.find(({ key }) => key === values.key);

        if (!field) return null;

        return field.key === HIRING_MANAGER_PAY_RATE_KEY
          ? HIRING_MANAGER_PAY_RATE_KEY
          : (field.type as unknown as GroupedQuestionsValues);
      }
      return null;
    });

  return (
    <>
      <Grid item xs={12}>
        <Input
          label={intl.formatMessage(questionFormQuestionLabel)}
          value={values.question}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange({
              question: event.target.value,
            })
          }
          error={Boolean(errors.question)}
          required
        />
        <Error error={errors.question} align="right" />
      </Grid>
      <Grid item xs={12}>
        <Input
          label={intl.formatMessage(questionFormQuestionHintLabel)}
          value={values.hint}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange({ hint: event.target.value })
          }
          error={Boolean(errors.hint)}
        />
        <Error error={errors.hint} align="right" />
      </Grid>
      <Grid item xs={12}>
        <StyledReactSelect
          label={intl.formatMessage(questionFormQuestionTypeLabel)}
          options={getGroupedQuestionOptions({
            questionnaireHasHiringManagerStandardQuestion:
              activeDataEntries.some(
                ({ key }) => key === HIRING_MANAGER_PAY_RATE_KEY,
              ),
          })}
          onChange={({ value }: GroupedQuestionOption['options'][number]) => {
            const defaults =
              value === HIRING_MANAGER_PAY_RATE_KEY
                ? createDefaultPayRateField
                : TYPE_TO_DEFAULT[value];

            setQuestionType(value);
            handleChange(
              defaults({
                position: activeDataEntries.length,
                question: values.question,
                hint: values.hint,
                key: values.key,
              }),
              { replace: true },
            );
          }}
          value={ALL_GROUPED_QUESTIONS.find(
            ({ value }) => value === questionType,
          )}
          isSearchable={false}
          error={Boolean(errors.type)}
          required
          formatGroupLabel={({ label }: GroupedQuestionOption) => label}
        />
        <Error error={errors.type} align="right" />
      </Grid>
      <Grid item xs={12}>
        <Input
          label={intl.formatMessage(questionFormQuestionDataKeyLabel)}
          value={values.key}
          required
          disabled={(STANDARD_QUESTION_VALUES as Array<string>).includes(
            values.key,
          )}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange({ key: event.target.value })
          }
          onBlur={() => {
            handleChange({ key: snakeCase(values.key.toLowerCase()) });
          }}
          error={Boolean(errors.key)}
        />
        <Error error={errors.key} align="right" />
      </Grid>
    </>
  );
};
