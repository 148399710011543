import {
  CustomNotificationPreference,
  CustomNotificationPreferenceParams,
  NotificationPreference,
} from 'api-clients/monolith';
import { filter, find, isEmpty, some } from 'lodash';
import React, { useState } from 'react';

// eslint-disable-next-line import/no-cycle
import { SelectStage } from './SelectStage';
import { StageOptionType } from './types';
import { createDeletedStageData } from './utils';

export interface SelectOpeningStageProps {
  notifiableType: string;
  notifiableId: string;
  notificationPreference: NotificationPreference;
  setStagesData: React.Dispatch<
    React.SetStateAction<CustomNotificationPreferenceParams[]>
  >;
}

export const SelectOpeningStage: React.FC<SelectOpeningStageProps> = ({
  notifiableType,
  notifiableId,
  notificationPreference,
  setStagesData,
}) => {
  const initialSelectedStages =
    notificationPreference?.custom_notification_preferences
      ?.filter(preference => !!preference.funnel_id)
      .map(preference => ({
        label: preference.entity_name,
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        value: `${preference.entity_id}:${preference.funnel_id}`,
      })) ?? [];
  const [selectedStages, setSelectedStages] = useState<StageOptionType[]>(
    initialSelectedStages,
  );

  const onChange = (stages: StageOptionType[]) => {
    setSelectedStages(stages);
    const deletedStages = filter(
      notificationPreference.custom_notification_preferences,
      preference => {
        return !some(
          stages,
          (option: StageOptionType) =>
            preference.funnel_id &&
            option.value === `${preference.entity_id}:${preference?.funnel_id}`,
        );
      },
    );

    const deletedStagesData = createDeletedStageData(deletedStages);
    const selectedStagesData = stages.map((option: StageOptionType) => {
      const [stageId, funnelId] = String(option.value).split(':');
      const id = notificationPreference.custom_notification_preferences
        ? find(
            notificationPreference.custom_notification_preferences,
            (preference: CustomNotificationPreference) =>
              preference.entity_id === stageId &&
              !(isEmpty(funnelId) && preference.funnel_id !== funnelId),
          )?.id
        : undefined;
      return {
        entity_id: stageId,
        entity_type: 'Stage',
        funnel_id: funnelId,
        id,
      };
    });

    setStagesData([...deletedStagesData, ...selectedStagesData]);
  };

  return (
    <SelectStage
      notifiableType={notifiableType}
      notifiableId={notifiableId}
      stageType="opening"
      selectedStages={selectedStages}
      onChange={onChange}
    />
  );
};
