import { OptionBanksService } from 'api-clients/monolith';
import { useApiService } from 'hooks';
import React from 'react';

export function useOptionBanks() {
  const fetchOptionBanks = React.useCallback(
    () => OptionBanksService.getInternalApiOptionBanksOptionBanks(),
    [],
  );

  return useApiService(fetchOptionBanks);
}
