import { SharedPreviewProps } from 'containers/DataValidationModal/components/FileField/types';
import { SharedFieldProps } from 'containers/DataValidationModal/components/types';
import {
  ApplicantBlob,
  MergedDetail,
} from 'containers/DataValidationModal/types';

export const TestId = {
  EDIT_BUTTON: 'DataValidationModal-EditButton',
  FIELD: 'DataValidationModal-Field',
  FILE_FIELD: 'DataValidationModal-FileField',
  TITLE: 'DataValidationModal-Title',
};

/* exposing test data as functions to prevent accidentally overriding in consumers */

export const getMergedDetail = (): MergedDetail => ({
  dataFieldType: 'data',
  format: undefined,
  format_validation_message: '',
  hidden_value: '',
  hint: '',
  id: 37,
  key: 'key',
  logic_jumps: [],
  option_bank_id: null,
  options: [
    {
      default: false,
      label: 'options-label',
      value: 'options-value',
    },
  ],
  predefined: false,
  position: 1,
  question: '',
  required: false,
  secure: false,
  restricted: false,
  selected_option: undefined,
  subtitle: null,
  title: 'title',
  type: 'text_field',
  value: null,
  format_source: null,
  extra: {
    locale: 'US',
    hidden_value: '',
  },
});

export const getApplicantBlob = (): ApplicantBlob => ({
  download_url: 'download_url',
  approved_at: undefined,
  approved_by: undefined,
  can_approve: false,
  downloadable: false,
  fieldname: 'fieldname',
  filename: 'filename',
  id: 37,
  preview_url: 'preview_url',
  previewable: false,
  recollectable: false,
  requested_reupload: false,
  reupload_last_requested_at: undefined,
  reupload_last_requested_by: undefined,
  to_param: '',
  url: 'url',
});

export const getSharedFieldProps = (): SharedFieldProps => ({
  applicantId: 'acc4136f-e3ef-40c4-9a2f-057059b0e33b', // random GUID
  detail: getMergedDetail(),
  savePayload: jest.fn(),
  setIsDirty: jest.fn(),
  type: 'data',
});

export const getSharedPreviewProps = (): SharedPreviewProps => ({
  applicantId: 'acc4136f-e3ef-40c4-9a2f-057059b0e33b', // random GUID
  detail: getMergedDetail(),
  file: new File([], 'file'),
  applicantBlob: [getApplicantBlob()],
  handleUpload: jest.fn(),
  setIsEditable: jest.fn(),
});
