import { Popper } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useSimpleToggle } from 'hooks';
import React, { FC, useEffect, useRef, useState } from 'react';

import { FilterDropdownButton } from 'components/FilterDropdown/Button';
import { FilterOpen } from 'components/FilterDropdown/FilterOpen';
import { FilterDropdownProps } from 'components/FilterDropdown/index';
import { Filterable, mapFilterable } from 'components/FilterDropdown/util';
import { useDebounce } from 'hooks/useDebounce';
import useLocations from 'hooks/useLocations';

const PER_PAGE = 50;

export const FilterLocationDropdown: FC<FilterDropdownProps<Filterable>> = ({
  selected,
  setSelected,
  showTitle,
  error,
}) => {
  const { off, showContent: open, toggle: openUp } = useSimpleToggle();
  const anchorRef = useRef<HTMLDivElement>(null);
  const [locations, setLocations] = useState<Filterable[]>([]);
  const [query, setQuery] = useState('');
  const debounceSetQuery = useDebounce(setQuery, 500);
  const { data, isFetching } = useLocations({
    query,
    perPage: PER_PAGE,
    page: 1,
  });
  const name = 'location';

  useEffect(() => {
    if (!isFetching) {
      setLocations(
        mapFilterable(data, {
          name,
          idAttr: 'id',
          titleAttr: 'name',
        }),
      );
    }
  }, [data, setLocations, isFetching]);

  return (
    <ClickAwayListener onClickAway={off}>
      <div>
        <FilterDropdownButton
          ref={anchorRef}
          name={name}
          open={open}
          openUp={openUp}
          count={selected.length}
          clearFilter={() => setSelected([])}
          titles={selected.map(filterable => filterable.title)}
          showTitle={showTitle}
          setSelected={setSelected}
          selected={selected}
          error={error}
        />
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          placement="bottom-start"
          style={{ zIndex: 1300, width: anchorRef.current?.clientWidth }}
          disablePortal={false}
        >
          <FilterOpen
            name={name}
            selected={selected}
            setSelected={setSelected}
            options={locations}
            setQuery={debounceSetQuery}
          />
        </Popper>
      </div>
    </ClickAwayListener>
  );
};
