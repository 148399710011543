import {
  Checkbox,
  Datepicker,
  Input,
  Radio,
  TextArea,
} from '@fountain/fountain-ui-components';
import { FormControlLabel, Grid, RadioGroup } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedDate, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import ApplicantAddress from 'components/ApplicantAddress';
import { DETAIL_TYPES } from 'components/ApplicantDrawerDetailsList/constants';
import { Error } from 'components/Error';
import LicenseDataContainer from 'components/LicenseDataContainer';
import ValueEditorDropdown from 'components/ValueEditorDropdown';
import WorkAvailabilityInput from 'components/WorkAvailabilityInput';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import globalMessages from 'shared/global/messages';
import { dateStringToDateObject } from 'utils/date';
import { getDateFormatPattern } from 'utils/datePlaceholder';

import { DetailType, DetailValueType, IdType } from '../../props';
import useStyles from './styles';

const EditingField = ({
  applicantId,
  value,
  detail,
  onDropdownChange,
  onValueChange,
  onDateChange,
  onCheckboxChange,
  onAvailabilityChange,
  onAddressChange,
  handleKeyPress,
  error,
  setIsDirty,
  setValue,
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const locale = useSelector(makeSelectLocale());
  const renderField = () => {
    const values = value || [];

    switch (detail.type) {
      case DETAIL_TYPES.availability:
        return (
          <>
            <WorkAvailabilityInput
              onChange={onAvailabilityChange(setValue, value, setIsDirty)}
              selectedOptions={value}
              error={error}
            />
            <Error
              className={classes.workAvailabilityErrorMessage}
              error={error}
            />
          </>
        );
      case DETAIL_TYPES.date_picker:
        // eslint-disable-next-line no-case-declarations
        const date = dateStringToDateObject(value);
        return (
          <>
            <Datepicker
              locale={locale}
              value={date}
              minDate={
                detail.minDate
                  ? dateStringToDateObject(detail.minDate)
                  : undefined
              }
              maxDate={
                detail.maxDate
                  ? dateStringToDateObject(detail.maxDate)
                  : undefined
              }
              onDateChange={onDateChange(setValue, setIsDirty)}
              error={error}
              renderDate={selectedDate => (
                <FormattedDate dateStyle="short" value={selectedDate} />
              )}
              renderPlaceholder={() => getDateFormatPattern(locale)}
              className={detail.key}
            />
            <Error error={error} align="right" />
          </>
        );
      case DETAIL_TYPES.dropdown:
        return (
          <>
            <ValueEditorDropdown
              key={detail.key}
              options={detail.options}
              value={value}
              onChange={onDropdownChange(setValue, setIsDirty)}
              optionBankId={detail.option_bank_id}
              partialUrl={`applicants/${applicantId}/info/data/search_options`}
              error={error}
              id={detail.key}
            />
            <Error error={error} align="right" />
          </>
        );
      case DETAIL_TYPES.checkboxes:
        return (
          <>
            {detail.options.map(option => (
              <FormControlLabel
                key={option.value}
                label={option.label}
                classes={{
                  label: classes.checkboxLabel,
                }}
                control={
                  <Checkbox
                    checked={values.includes(option.value)}
                    onChange={onCheckboxChange(value, setValue, setIsDirty)}
                    value={option.value}
                    inputProps={{
                      'aria-label': 'primary checkbox',
                    }}
                    error={error}
                    id={detail.key}
                  />
                }
              />
            ))}
            <Error error={error} align="left" />
          </>
        );
      case DETAIL_TYPES.radio:
        return (
          <RadioGroup
            aria-label={detail.key}
            name={detail.key}
            value={(value ?? '').toString()}
            onChange={onValueChange(setValue, setIsDirty)}
            error={error}
            id={detail.key}
          >
            {detail.options.map(option => (
              <Radio
                value={option.value.toString()}
                key={option.value}
                label={option.label}
                className={classes.radio}
                error={error}
              />
            ))}
            <Error error={error} className={classes.radioError} align="left" />
          </RadioGroup>
        );
      case DETAIL_TYPES.text_area:
        return (
          <>
            <TextArea
              value={value}
              onChange={onValueChange(setValue, setIsDirty)}
              error={error}
              rows="3"
              id={detail.key}
            />
            <Error error={error} align="right" />
          </>
        );
      case DETAIL_TYPES.address:
        return (
          <>
            <ApplicantAddress
              address={value}
              onAddressChange={onAddressChange}
              setValue={setValue}
              setIsDirty={setIsDirty}
              placeholder={formatMessage(globalMessages.enterValue)}
              handleKeyPress={handleKeyPress}
              error={error || {}}
              id={detail.key}
            />
          </>
        );
      case DETAIL_TYPES.license_data:
        return (
          <>
            <LicenseDataContainer
              licenseData={value}
              handleKeyPress={handleKeyPress}
              setValue={setValue}
              setIsDirty={setIsDirty}
              id={detail.key}
            />
          </>
        );
      default:
        return (
          <>
            <Input
              value={value}
              name={`${detail.title}-value`}
              onChange={onValueChange(setValue, setIsDirty)}
              onKeyPress={handleKeyPress}
              error={!!error}
              id={detail.key}
            />
            <Error error={error} align="right" />
          </>
        );
    }
  };

  return (
    <Grid data-testid="ApplicantDrawerDetailsList-EditingField">
      {renderField()}
    </Grid>
  );
};

EditingField.propTypes = {
  applicantId: IdType.isRequired,
  value: DetailValueType,
  detail: DetailType.isRequired,
  onDropdownChange: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  onAvailabilityChange: PropTypes.func.isRequired,
  onAddressChange: PropTypes.func.isRequired,
  handleKeyPress: PropTypes.func,
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
    PropTypes.string,
  ]),
  setIsDirty: PropTypes.func,
  setValue: PropTypes.func,
};

export default EditingField;
