import { FormControlLabel, Grid, Switch, Typography } from '@material-ui/core';
import {
  WorkflowSchedulingStage,
  WorkflowStageDetail,
} from 'api-clients/monolith';
import produce from 'immer';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { StageSettingCard } from '../../../../StageSettingCard';
import { LIVE_VIDEO_ENABLED } from './constants';
import { messages } from './messages';
import { useStyles } from './styles';

export interface LiveVideoCardProps {
  stage: WorkflowSchedulingStage;
  setStage: React.Dispatch<React.SetStateAction<WorkflowStageDetail>>;
}

export const LiveVideoCard: FC<LiveVideoCardProps> = ({ stage, setStage }) => {
  const styles = useStyles();
  const intl = useIntl();

  const { additional_info: additionalInfo } = stage;

  const onToggleChange = (key: typeof LIVE_VIDEO_ENABLED) => () => {
    setStage(
      produce(stage, draftStage => {
        if (draftStage.additional_info) {
          draftStage.additional_info[key] = !draftStage.additional_info[key];
        }
      }),
    );
  };

  return (
    <StageSettingCard
      variant="default"
      title={intl.formatMessage(messages.liveVideo)}
    >
      <Grid>
        <Typography variant="body2" className={styles.description}>
          {intl.formatMessage(messages.liveVideoDescription)}
          <a
            href="https://support.fountain.com/en/articles/9899557-live-video"
            target="_blank"
            rel="noopener noreferrer"
          >
            {intl.formatMessage(messages.learnMore)}
          </a>
        </Typography>
      </Grid>
      <Grid className={styles.cardBody}>
        <Grid item xs={12} className={styles.fieldRow}>
          <Grid item xs={12} className={styles.fieldRow}>
            <FormControlLabel
              control={
                <Switch
                  data-testid={LIVE_VIDEO_ENABLED}
                  checked={additionalInfo?.live_video_enabled}
                  onChange={onToggleChange(LIVE_VIDEO_ENABLED)}
                />
              }
              label={
                <Typography variant="body2" className={styles.switchLabel}>
                  {intl.formatMessage(messages.enableLiveVideoCall)}
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </StageSettingCard>
  );
};
