import {
  ClickAwayListener,
  createStyles,
  makeStyles,
  Popper,
  Theme,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ApproversObj } from 'api-clients/monolith';
import { useSimpleToggle } from 'hooks';
import React, { FC, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { FilterDropdownButton } from 'components/FilterDropdown/Button';
import SearchInput from 'components/SearchInput';

import messages from './messages';
import { useStyles as useSharedStyles } from './styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popper: {
      border: theme.customBorders.gray300,
      boxShadow: theme.customShadows.selectDropdown,
      borderRadius: theme.misc.borderRadius,
      backgroundColor: theme.palette.common.white,
      width: 300,
      zIndex: 1000,
    },
    inputBase: {
      padding: theme.spacing(1),
      width: '100%',
    },
    paper: {
      boxShadow: 'none',
      margin: 0,
      color: theme.palette.common.white,
    },
    option: {
      color: theme.palette.common.black,
    },
    popperDisablePortal: {
      position: 'relative',
      width: '100% !important',
    },
  }),
);

export interface ApproversDropdownProps {
  approvers: ApproversObj[];
  selectedApprovers: ApproversObj[];
  onChange: (selectedUsers: ApproversObj[]) => void;
}

export const ApproversDropdown: FC<ApproversDropdownProps> = ({
  approvers,
  selectedApprovers,
  onChange,
}) => {
  const { off, showContent: open, toggle: openUp } = useSimpleToggle();
  const anchorRef = useRef<HTMLDivElement>(null);

  const styles = useStyles();
  const sharedStyles = useSharedStyles();

  return (
    <>
      <ClickAwayListener onClickAway={off}>
        <div>
          <div className={sharedStyles.modalDropdown}>
            <FilterDropdownButton
              titles={selectedApprovers.map(approver => approver.name)}
              ref={anchorRef}
              name="custom"
              open={open}
              openUp={openUp}
              count={selectedApprovers.length}
              clearFilter={() => onChange([])}
              showTitle
              setSelected={selected => {
                const selectedIds = new Set(selected.map(s => s.id));
                const filteredApprovers = selectedApprovers.filter(approver =>
                  selectedIds.has(approver.id),
                );
                onChange(filteredApprovers);
              }}
              selected={selectedApprovers.map(approver => ({
                title: approver.name,
                id: approver.id,
                name: 'custom',
              }))}
              customPlaceholder={
                <FormattedMessage {...messages.approvalRuleUsersLabel} />
              }
            />
          </div>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            placement="bottom-start"
            style={{ zIndex: 1300, width: anchorRef.current?.clientWidth }}
            className={styles.popper}
            modifiers={{
              preventOverflow: {
                enabled: true,
                boundariesElement: 'viewport',
              },
            }}
          >
            <Autocomplete
              open
              multiple
              filterSelectedOptions
              value={selectedApprovers}
              getOptionSelected={(
                option: ApproversObj,
                value: ApproversObj,
              ) => {
                return option.id === value.id;
              }}
              classes={{
                paper: styles.paper,
                option: styles.option,
                popperDisablePortal: styles.popperDisablePortal,
              }}
              onChange={(event, approvers) => {
                if (
                  event.nativeEvent instanceof KeyboardEvent &&
                  event.nativeEvent.key === 'Backspace'
                ) {
                  return;
                }

                onChange(approvers);
              }}
              disableCloseOnSelect
              disablePortal
              renderOption={option => (
                <div>
                  <Typography variant="caption">{option.name}</Typography>
                  <Typography variant="body2">{option.email}</Typography>
                </div>
              )}
              options={approvers}
              getOptionLabel={approver => approver.name}
              renderInput={params => (
                <SearchInput
                  ref={params.InputProps.ref}
                  // @ts-expect-error this will go away when converting this component to typescript
                  inputProps={params.inputProps}
                  autoFocus
                  placeholder="Search users"
                  className={styles.inputBase}
                />
              )}
            />
          </Popper>
        </div>
      </ClickAwayListener>
    </>
  );
};
