import {
  WorkflowAccurateStage,
  WorkflowArchivedStage,
  WorkflowAssessmentStage,
  WorkflowBackgroundCheckerStage,
  WorkflowDataCollectionStage,
  WorkflowDocumentSignatureStage,
  WorkflowDocumentSigningStage,
  WorkflowI9FormStage,
  WorkflowJobMatcherStage,
  WorkflowMultiStage,
  WorkflowPartnerStage,
  WorkflowRejectedStage,
  WorkflowSchedulerStage,
  WorkflowSterlingStage,
  WorkflowVideoRecordingStage,
  WorkflowW4FormStage,
} from 'api-clients/monolith';
import React, { useContext, VFC } from 'react';
import { useIntl } from 'react-intl';

import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import { StageSettingCard } from '../StageSettingCard';
import {
  AllowRejectInGo,
  GoEnabled,
  SchedulerStageAllowRejectInGo,
  SchedulerStageGoEnabled,
} from '../WorkflowEditorContent/StageDetail/SettingsSwitches';
import { messages } from './messages';

const STAGES_WITHOUT_ALLOW_REJECT_IN_GO = ['RejectedStage'];

type StagesWithAdvancedSettings =
  | WorkflowAccurateStage
  | WorkflowArchivedStage
  | WorkflowAssessmentStage
  | WorkflowBackgroundCheckerStage
  | WorkflowBackgroundCheckerStage
  | WorkflowDataCollectionStage
  | WorkflowDocumentSignatureStage
  | WorkflowDocumentSigningStage
  | WorkflowI9FormStage
  | WorkflowJobMatcherStage
  | WorkflowMultiStage
  | WorkflowPartnerStage
  | WorkflowRejectedStage
  | WorkflowSchedulerStage
  | WorkflowSterlingStage
  | WorkflowVideoRecordingStage
  | WorkflowW4FormStage;

export const AdvancedSettings: VFC<{ stage: StagesWithAdvancedSettings }> = ({
  stage,
}) => {
  const intl = useIntl();
  const { setStage } = useContext(StageContext);
  const isSchedulerStage = stage.type === 'SchedulerStage';
  const showAllowRejectInGo = !STAGES_WITHOUT_ALLOW_REJECT_IN_GO.includes(
    stage.type,
  );

  return (
    <StageSettingCard
      variant="default"
      title={intl.formatMessage(messages.advancedSettings)}
    >
      {isSchedulerStage ? (
        <>
          <SchedulerStageGoEnabled
            stage={stage}
            setStage={
              setStage as React.Dispatch<
                React.SetStateAction<WorkflowSchedulerStage>
              >
            }
          />
          <SchedulerStageAllowRejectInGo
            stage={stage}
            setStage={
              setStage as React.Dispatch<
                React.SetStateAction<WorkflowSchedulerStage>
              >
            }
          />
        </>
      ) : (
        <>
          <GoEnabled stage={stage} setStage={setStage} />
          {showAllowRejectInGo && (
            <AllowRejectInGo stage={stage} setStage={setStage} />
          )}
        </>
      )}
    </StageSettingCard>
  );
};
