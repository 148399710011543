import { UserNotificationPreferencesService } from 'api-clients/monolith';
import { useApiService } from 'hooks';
import React from 'react';

export function useNotifiableData({
  notifiableType,
  notifiableId,
}: {
  notifiableType: string;
  notifiableId: string;
}) {
  // Gets a list of notification preferences with Role name
  const fetchRolesData = React.useCallback(
    () =>
      UserNotificationPreferencesService.getInternalApiNotificationPreferences(
        notifiableType,
        notifiableId,
      ),
    [notifiableId, notifiableType],
  );

  return useApiService(fetchRolesData);
}
