import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { REACT_APP_GLOBAL_API_BASE_URL_V2 } from 'runtimeEnvVars';

import { addDefaultErrorMessageAction } from 'containers/FlashMessage/actions';
import { apiGet } from 'utils/axios';

interface Location {
  id: string;
  name: string;
  // eslint-disable-next-line camelcase
  account_external_id: string;
}

const baseUrl = `${REACT_APP_GLOBAL_API_BASE_URL_V2}/locations`;

export default function useLocations({
  query,
  perPage,
  page,
  includeSubAccounts,
}: {
  query?: string;
  perPage?: number;
  page?: number;
  includeSubAccounts?: boolean;
} = {}) {
  const dispatch = useDispatch();
  const [data, setData] = useState<Location[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsFetching(true);
      try {
        const params = new URLSearchParams();
        if (query) params.append('query', query);
        if (perPage) params.append('per_page', perPage.toString());
        if (page) params.append('page', page.toString());
        if (includeSubAccounts) params.append('include_sub_accounts', 'true');

        const queryString = params.toString();
        const url = queryString ? `${baseUrl}?${queryString}` : baseUrl;

        const res = (await apiGet(url)) as AxiosResponse<{
          locations: Location[];
        }>;
        setData(res.data.locations);
      } catch (e) {
        dispatch(addDefaultErrorMessageAction());
      } finally {
        setIsFetching(false);
      }
    };
    void fetchData();
  }, [dispatch, query, perPage, page, includeSubAccounts]);

  return { data, isFetching };
}
