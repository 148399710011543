import { defineMessages } from 'react-intl';

export default defineMessages({
  approvalRule: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.approvalRule',
    defaultMessage: 'Approval rule',
  },
  createApprovalRule: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.createApprovalRule',
    defaultMessage: 'Create Approval Rule',
  },
  editApprovalRule: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.editApprovalRule',
    defaultMessage: 'Edit Approval Rule',
  },
  approversModalSubtitle: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.approversModalSubtitle',
    defaultMessage:
      'Create a rule to set which openings require approval. Openings that meet the criteria you set will require approval before being set to active.',
  },
  approversModalEditSubtitle: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.approversModalEditSubtitle',
    defaultMessage:
      'Edit the rule to set which openings require approval. Openings that meet the criteria you set will require approval before being set to active.',
  },
  approversModalSelectInfo: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.approversModalSelectInfo',
    defaultMessage:
      'A minimum of one and up to four approvers can be added in a group.',
  },
  ruleNameLabel: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.ruleNameLabel',
    defaultMessage: 'Rule name',
  },
  approvalRuleUsersLabel: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.approvalRuleUsersLabel',
    defaultMessage: 'Select approvers',
  },
  deleteConfirmationModalContent: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.deleteConfirmationModalContent',
    defaultMessage:
      'Are you sure you want to delete the selected approver group?',
  },
  deleteConfirmationModalTitle: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.deleteConfirmationModalTitle',
    defaultMessage: 'Delete Approver group',
  },
  emptyTableMessage: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.emptyTableMessage',
    defaultMessage: 'No approver group created yet.',
  },
  warningMessage: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.warningMessage',
    defaultMessage:
      'At least one approver group needs to be set up in order to use Opening Approvals.',
  },
  searchEmptyTableMessage: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.searchEmptyTableMessage',
    defaultMessage: 'No Approver Groups found.',
  },
  selectPlaceolderText: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.selectPlaceolderText',
    defaultMessage: 'Select or search users',
  },
  inputPlaceholderText: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.inputPlaceholderText',
    defaultMessage: 'Name',
  },
  createSuccessMessage: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.createSuccessMessage',
    defaultMessage: 'Approver Group created Successfully',
  },
  updateSuccessMessage: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.updateSuccessMessage',
    defaultMessage: 'Approver Group updated Successfully',
  },
  deleteSuccessMessage: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.deleteSuccessMessage',
    defaultMessage: 'Approver Group deleted Successfully',
  },
  maxApproversErrorMessage: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.maxApproversErrorMessage',
    defaultMessage: 'Maximum of only 4 approvers can be added to a group.',
  },
  groupNameErrorMessage: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.groupNameErrorMessage',
    defaultMessage: 'Please enter the approver group name.',
  },
  emptyOpeningsErrorMessage: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.emptyOpeningsErrorMessage',
    defaultMessage: 'Please select openings to apply.',
  },
  requireApprovalForLabel: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.requireApprovalForLabel',
    defaultMessage: 'Require approval for',
  },
  specifiedOpeningsRadioLabel: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.specifiedOpeningsRadioLabel',
    defaultMessage: 'Specified openings',
  },
  locationsThatRequireOpeningApprovalLabel: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.locationsThatRequireOpeningApprovalLabel',
    defaultMessage: 'Locations that require opening approval',
  },
  appliedTo: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.appliedTo',
    defaultMessage: 'Applied to',
  },
  allOpenings: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.allOpenings',
    defaultMessage: 'All openings',
  },
  overlappingRule: {
    id: 'app.containers.OpeningApprovals.ApproverGroups.overlappingRule',
    defaultMessage:
      'One or more locations in this rule are also included in other rules. These locations will require an especially high number of approvals.',
  },
});
