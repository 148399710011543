import { Input } from '@fountain/fountain-ui-components';
import { Grid, InputLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Error } from 'components/Error';
import { isEmptyAddress } from 'utils/checkEmptyAddress';

import messages from './messages';
import useStyles from './styles';

export const ApplicantAddress = ({
  address,
  onAddressChange,
  editDisabled,
  handleKeyPress,
  error,
  setValue,
  setIsDirty,
  id,
}) => {
  const classes = useStyles();
  let addr = address === '' ? {} : address;
  const {
    street_name: streetName,
    address_2: address2,
    city,
    state,
    zipcode,
    country,
    county,
  } = addr;
  const fields = {
    street_name: 'street_name',
    address_2: 'address_2',
    city: 'city',
    state: 'state',
    zipcode: 'zipcode',
    country: 'country',
    county: 'county',
  };
  const onFieldChange = (e, fieldName) => {
    addr = {
      ...addr,
      [fieldName]: e.target.value,
    };

    onAddressChange(addr, setValue, setIsDirty);
  };
  const handleChangeField = fieldName => e => {
    onFieldChange(e, fieldName);
  };

  const showCounty = county !== undefined;

  const renderEditField = () => (
    <Grid>
      <Grid className={classes.input}>
        <InputLabel className={classes.label} htmlFor={fields.street_name}>
          <FormattedMessage {...messages.streetName} />
        </InputLabel>
        <Input
          value={streetName}
          onChange={handleChangeField(fields.street_name)}
          onKeyPress={handleKeyPress}
          error={!!error.street_name}
          id={id}
        />
        <Error error={!!error.street_name} align="right" />
      </Grid>
      <Grid className={classes.input}>
        <InputLabel className={classes.label} htmlFor={fields.address_2}>
          <FormattedMessage {...messages.address2} />
        </InputLabel>
        <Input
          value={address2}
          onChange={handleChangeField(fields.address_2)}
          onKeyPress={handleKeyPress}
        />
      </Grid>
      <Grid className={classes.input}>
        <InputLabel className={classes.label} htmlFor={fields.city}>
          <FormattedMessage {...messages.city} />
        </InputLabel>
        <Input
          value={city}
          onChange={handleChangeField(fields.city)}
          onKeyPress={handleKeyPress}
          error={!!error.city}
        />
        <Error error={!!error.city} align="right" />
      </Grid>
      {showCounty && (
        <Grid className={classes.input}>
          <InputLabel className={classes.label} htmlFor={fields.county}>
            <FormattedMessage {...messages.county} />
          </InputLabel>
          <Input
            value={county}
            onChange={handleChangeField(fields.county)}
            onKeyPress={handleKeyPress}
          />
        </Grid>
      )}
      <Grid className={classes.input}>
        <InputLabel className={classes.label} htmlFor={fields.state}>
          <FormattedMessage {...messages.state} />
        </InputLabel>
        <Input
          value={state}
          onChange={handleChangeField(fields.state)}
          onKeyPress={handleKeyPress}
          error={!!error.state}
        />
        <Error error={!!error.state} align="right" />
      </Grid>
      <Grid className={classes.input}>
        <InputLabel className={classes.label} htmlFor={fields.zipcode}>
          <FormattedMessage {...messages.zipCode} />
        </InputLabel>
        <Input
          value={zipcode}
          onChange={handleChangeField(fields.zipcode)}
          onKeyPress={handleKeyPress}
          error={!!error.zipcode}
        />
        <Error error={!!error.zipcode} align="right" />
      </Grid>
      <Grid className={classes.input}>
        <InputLabel className={classes.label} htmlFor={fields.country}>
          <FormattedMessage {...messages.country} />
        </InputLabel>
        <Input
          value={country}
          onChange={handleChangeField(fields.country)}
          onKeyPress={handleKeyPress}
          error={!!error.country}
        />
        <Error error={!!error.country} align="right" />
      </Grid>
    </Grid>
  );

  const renderValue = () =>
    isEmptyAddress(addr) ? (
      <p className={classes.p}>-</p>
    ) : (
      <p className={classes.p}>
        {streetName} {streetName ? <br /> : null}
        {address2} {address2 ? <br /> : null}
        {city}
        {city ? ', ' : null}
        {state}
        {state ? ' ' : null}
        {zipcode}
        {zipcode ? ' ' : null}
        {city || state || zipcode ? <br /> : null}
        {country}
      </p>
    );

  return editDisabled ? renderValue() : renderEditField();
};

ApplicantAddress.propTypes = {
  address: PropTypes.object,
  onAddressChange: PropTypes.func.isRequired,
  editDisabled: PropTypes.bool,
  handleKeyPress: PropTypes.func.isRequired,
  error: PropTypes.shape({
    streetName: PropTypes.bool,
    city: PropTypes.bool,
    state: PropTypes.bool,
    zipcode: PropTypes.bool,
    country: PropTypes.bool,
  }),
};

ApplicantAddress.defaultProps = {
  editDisabled: false,
};

export default ApplicantAddress;
