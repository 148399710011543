import { ShortType, StageType } from 'api-clients/monolith';
import React, { FC } from 'react';
import { classNames } from 'react-extras';

import {
  AIInterviewIcon,
  ArchivedStageIcon,
  AssessmentStageIcon,
  BackgroundCheckStageIcon,
  CustomStageIcon,
  DataCollectionStageIcon,
  DocumentSigningStageIcon,
  FormStageIcon,
  HiredStageIcon,
  JobSelectorStageIcon,
  LearningStageIcon,
  MultiStageIcon,
  PartnerStageIcon,
  RejectedStageIcon,
  ReviewStageIcon,
  RuleStageIcon,
  SchedulerStageIcon,
  TechCheckStageIcon,
  VideoRecordingStageIcon,
} from 'containers/WorkflowEditor/StageIcons';
import { JobSwitcherStageIcon } from 'containers/WorkflowEditor/StageIcons/JobSwitcherStageIcon';

import { useStyles } from './styles';

export const StageIconByType: FC<{
  type: StageType;
  shortType?: ShortType;
}> = ({ type, shortType }) => {
  const styles = useStyles();

  switch (type) {
    case 'ArchivedStage':
      return (
        <ArchivedStageIcon
          data-testid={`${type}Icon`}
          color="inherit"
          className={classNames(styles.icon, styles.archivedStageIcon)}
        />
      );
    case 'AssessmentStage':
      return (
        <AssessmentStageIcon
          data-testid={`${type}Icon`}
          className={classNames(styles.icon, styles.assessmentStageIcon)}
        />
      );
    case 'AccurateStage':
    case 'BackgroundCheckerStage':
    case 'SterlingStage':
    case 'CheckrCanada::Stage':
    case 'NewCheckrCanada::Stage':
    case 'OnfidoStage':
      return (
        <BackgroundCheckStageIcon
          data-testid={`${type}Icon`}
          className={classNames(styles.icon, styles.backgroundCheckIcon)}
        />
      );
    case 'CustomStage':
      return (
        <CustomStageIcon
          data-testid={`${type}Icon`}
          className={classNames(styles.icon, styles.customStageIcon)}
        />
      );
    case 'DocumentSignatureStage':
    case 'DocumentSigningStage':
    case 'FountainDocumentSigningStage':
      return (
        <DocumentSigningStageIcon
          data-testid={`${type}Icon`}
          className={classNames(styles.icon, styles.documentSigningStageIcon)}
        />
      );
    case 'I9FormStage':
    case 'W4FederalFormStage':
    case 'W4StateFormStage':
      return (
        <FormStageIcon
          data-testid={`${type}Icon`}
          className={classNames(styles.icon, styles.formStageIcon)}
        />
      );
    case 'DataCollectionStage':
      return (
        <DataCollectionStageIcon
          data-testid={`${type}Icon`}
          className={classNames(styles.icon, styles.dataCollectionStageIcon)}
        />
      );
    case 'HiredStage':
      return (
        <HiredStageIcon
          data-testid={`${type}Icon`}
          className={classNames(styles.icon, styles.hiredStageIcon)}
        />
      );
    case 'SchoolkeepStage':
    case 'LearningStage':
      return (
        <LearningStageIcon
          data-testid={`${type}Icon`}
          className={classNames(styles.icon, styles.learningStageIcon)}
        />
      );
    case 'MultiStage':
      return (
        <MultiStageIcon
          data-testid={`${type}Icon`}
          className={classNames(styles.icon, styles.multiStageIcon)}
        />
      );
    case 'PartnerStage':
      if (shortType === 'AI Interview') {
        return (
          <AIInterviewIcon
            data-testid="AIInterviewIcon"
            className={classNames(styles.icon, styles.aiInterviewStageIcon)}
          />
        );
      }
      return (
        <PartnerStageIcon
          data-testid={`${type}Icon`}
          className={classNames(styles.icon, styles.partnerStageIcon)}
        />
      );
    case 'RejectedStage':
      return (
        <RejectedStageIcon
          data-testid={`${type}Icon`}
          className={classNames(styles.icon, styles.rejectedStageIcon)}
        />
      );
    case 'ReviewStage':
      return (
        <ReviewStageIcon
          data-testid={`${type}Icon`}
          className={classNames(styles.icon, styles.ReviewStageIcon)}
        />
      );

    case 'DistributeApplicantsRuleStage':
    case 'RuleStage':
      return (
        <RuleStageIcon
          data-testid={`${type}Icon`}
          className={classNames(styles.icon, styles.ruleStageIcon)}
        />
      );
    case 'JobSelectorStage':
      return (
        <JobSelectorStageIcon
          data-testid={`${type}Icon`}
          className={classNames(styles.icon, styles.jobSelectorStageIcon)}
        />
      );
    case 'JobSwitcherStage':
      return (
        <JobSwitcherStageIcon
          data-testid={`${type}Icon`}
          className={classNames(styles.icon, styles.jobSwitcherStageIcon)}
        />
      );
    case 'SchedulerV2Stage':
    case 'SchedulerStage':
    case 'EventStage':
      return (
        <SchedulerStageIcon
          data-testid={`${type}Icon`}
          className={classNames(styles.icon, styles.schedulerStageIcon)}
        />
      );
    case 'TechCheckStage':
      return (
        <TechCheckStageIcon
          data-testid={`${type}Icon`}
          className={classNames(styles.icon, styles.techCheckStageIcon)}
        />
      );
    case 'VideoRecordingStage':
      return (
        <VideoRecordingStageIcon
          data-testid={`${type}Icon`}
          className={classNames(styles.icon, styles.archivedStageIcon)}
        />
      );
    default:
      return null;
  }
};
