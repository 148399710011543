import { defineMessages } from 'react-intl';

export default defineMessages({
  helmet: {
    id: 'app.containers.ManageNotificationPreference.helmet',
    defaultMessage: 'Manage Notifications',
  },
  header: {
    id: 'app.containers.ManageNotificationPreference.header',
    defaultMessage: 'Manage Notifications for',
  },
  roleDetails: {
    id: 'app.containers.ManageNotificationPreference.roleDetails',
    defaultMessage: 'Your role:',
  },
  subTitle: {
    id: 'app.containers.ManageNotificationPreference.subTitle',
    defaultMessage:
      'Users will receive notifications via email only for the openings they have access to.',
  },
  notificationUpdateSuccessfull: {
    id: 'app.containers.ManageNotificationPreference.notificationUpdateSuccessfull',
    defaultMessage: 'Notification preference updated successfully!',
  },
  notificationUpdateErrored: {
    id: 'app.containers.ManageNotificationPreference.notificationUpdateErrored',
    defaultMessage: "Notification preference couldn't updated!",
  },
  modalHeader: {
    id: 'app.containers.ManageNotificationPreference.modalHeader',
    defaultMessage: 'Edit Notification Preferences',
  },
  modalSubHeader: {
    id: 'app.containers.ManageNotificationPreference.modalSubHeader',
    defaultMessage:
      'The notifications will be applicable for the stages selected below',
  },
  closeModal: {
    id: 'app.containers.ManageNotificationPreference.closeModal',
    defaultMessage: 'Cancel',
  },
  saveCustomPreference: {
    id: 'app.containers.ManageNotificationPreference.saveCustomPreference',
    defaultMessage: 'Save',
  },
  selectPlaceolderText: {
    id: 'app.containers.ManageNotificationPreference.selectPlaceolderText',
    defaultMessage: 'Select stages',
  },
  confirmationModalActionText: {
    id: 'app.containers.ManageNotificationPreference.confirmationModalActionText',
    defaultMessage: 'Confirm',
  },
  confirmationModalTitle: {
    id: 'app.containers.ManageNotificationPreference.confirmationModalTitle',
    defaultMessage: 'Are you sure?',
  },
  confirmationModalContent: {
    id: 'app.containers.ManageNotificationPreference.confirmationModalContent',
    defaultMessage:
      'You are switching from {previousStageType} to {currentStageType} stages. Doing so will delete the current {previousStageType} stages with the selected {currentStageType} stages.',
  },
  stageMissingError: {
    id: 'app.containers.ManageNotificationPreference.stageMissingError',
    defaultMessage:
      'Please select atleast one stage that you want to subscribe.',
  },
});
