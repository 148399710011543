/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Pagination } from '../models/Pagination';
import type { SourcingOpenings } from '../models/SourcingOpenings';
import type { SourcingOpeningsPayload } from '../models/SourcingOpeningsPayload';
import type { VonqContract } from '../models/VonqContract';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SourcingService {

    /**
     * Requests sourcing openings
     * Return sourcing opening for the logged in user
     * @param page page number in result set
     * @param perPage number of results to return per page
     * @param sortBy field to sort results by
     * @param sortDirection direction to sort results
     * @param query Filter openings returned by matching opening/location/position name
     * @param positonIds Filter openings returned by matching positon_ids
     * @param locationIds Filter openings returned by matching location_ids
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiSourcingDashboardOpenings(
        page?: number,
        perPage?: number,
        sortBy?: string,
        sortDirection?: 'asc' | 'desc',
        query?: string,
        positonIds?: string,
        locationIds?: string,
    ): CancelablePromise<{
        data: Array<SourcingOpenings>;
        pagination: Pagination;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/sourcing/dashboard/openings',
            query: {
                'page': page,
                'per_page': perPage,
                'sort_by': sortBy,
                'sort_direction': sortDirection,
                'query': query,
                'positon_ids': positonIds,
                'location_ids': locationIds,
            },
        });
    }

    /**
     * Update openings for sourcing
     * @param requestBody
     * @returns any successful
     * @throws ApiError
     */
    public static postInternalApiSourcingOpeningsMarkForSourcing(
        requestBody: SourcingOpeningsPayload,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/sourcing/openings/mark_for_sourcing',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `unprocessable entity`,
            },
        });
    }

    /**
     * Creates a Vonq contract
     * @param requestBody
     * @returns VonqContract Vonq contract created
     * @throws ApiError
     */
    public static postInternalApiSourcingVonqContracts(
        requestBody: VonqContract,
    ): CancelablePromise<VonqContract> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/sourcing/vonq_contracts',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Deletes a Vonq contract
     * @param vonqContractId ID of the Vonq contract to delete
     * @returns any Contract successfully deleted
     * @throws ApiError
     */
    public static deleteInternalApiSourcingVonqContracts(
        vonqContractId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/internal_api/sourcing/vonq_contracts/{vonq_contract_id}',
            path: {
                'vonq_contract_id': vonqContractId,
            },
            errors: {
                404: `Not found`,
            },
        });
    }

}