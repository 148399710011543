import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  approvalContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    flexGrow: 1,
    padding: theme.spacing(2, 7),
    maxWidth: 1600,
    margin: 'auto',
    overflowY: 'auto',
    height: 'calc(100vh - (52px))',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  backButton: {
    marginRight: 'auto',
    marginBottom: theme.spacing(1),
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
  },
  tab: {
    height: '44px',
    minWidth: 'unset',
    padding: theme.spacing(1.75, 1),
    marginRight: theme.spacing(2),
  },
  indicator: {
    backgroundColor: theme.palette.common.blue400,
    height: '4px',
  },
  tabContent: {
    overflowY: 'auto',
    height: '100%',
  },
}));
