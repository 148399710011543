import { defineMessages } from 'react-intl';

export default defineMessages({
  addAnotherOpening: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.DistributeApplicantsRuleStage.addAnotherOpening`,
    defaultMessage: 'Add another opening',
  },
  distributeToSubAccountDescription: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.DistributeApplicantsRuleStage.distributeToSubAccountDescription`,
    defaultMessage:
      'When distributing an applicant into a sub-account, the applicant will be cloned into the sub-account with the same name, email, phone number and data. No documents, secured data, or partner data will be included with the cloned applicant. The original applicant will automatically move into the next stage in this workflow.',
  },
  distributeApplicantsRuleStageDescription: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.DistributeApplicantsRuleStage.distributeApplicantsRuleStageDescription`,
    defaultMessage:
      'With Distribute Applicants rules, a pre-set percentage of incoming applicants can be moved across selected openings. E.g. if 8 applicants land on this rule over the course of a day, and 4 openings are pre-set to receive 25% each, 2 applicants will have been moved to each of 4 openings.',
  },
  distributeApplicantsRuleStageHiringNeedDescription: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.DistributeApplicantsRuleStage.distributeApplicantsRuleStageHiringNeedDescription`,
    defaultMessage:
      'Incoming applicants are moved across selected opening(s) based on a combination of your hiring goals, hires to date and opening conversion rates of each opening.',
  },
  emptyFieldsValidation: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.DistributeApplicantsRuleStage.emptyFieldsValidation`,
    defaultMessage: 'Please populate or remove empty fields before saving.',
  },
  maxRowsReached: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.DistributeApplicantsRuleStage.maxRowsReached`,
    defaultMessage: 'Maximum number of 20 rows reached',
  },
  percentageDeductMessage: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.DistributeApplicantsRuleStage.percentageDeductMessage`,
    defaultMessage:
      'Please deduct {percentageDifference}% to ensure total sums to 100%',
  },
  percentageSumMessage: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.DistributeApplicantsRuleStage.percentageSumMessage`,
    defaultMessage:
      'Please add {percentageDifference}% to ensure total sums to 100%',
  },
  pleaseProvideValidSelection: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.DistributeApplicantsRuleStage.pleaseProvideValidSelection`,
    defaultMessage: 'Please provide a valid selection for all required fields',
  },
  save: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.DistributeApplicantsRuleStage.save`,
    defaultMessage: 'Save',
  },
});
