/* eslint-disable @typescript-eslint/no-misused-promises */

import { CustomTooltip, Loader } from '@fountain/fountain-ui-components';
import {
  Link,
  Switch,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import {
  NotificationPreference,
  UserNotificationPreferencesService,
} from 'api-clients/monolith';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { addMessageAction } from 'containers/FlashMessage/actions';

import { CustomizeNotificationPreferenceModal } from './CustomizeNotificationPreferenceModal';
import messages from './messages';
import { useStyles } from './styles';

export interface NotificationPreferenceTableBodyProps {
  notifiableType: string;
  notifiableId: string;
  notificationPreferences: NotificationPreference[];
}

export const NotificationPreferenceTableBody: React.FC<
  NotificationPreferenceTableBodyProps
> = ({ notifiableType, notifiableId, notificationPreferences }) => {
  const styles = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedNotificationPreference, setSelectedNotificationPreference] =
    useState<string | null>(null);
  const [notificationPreferencesByKey, setNotificationPreferencesByKey] =
    useState<{ [key: string]: NotificationPreference }>(
      notificationPreferences.reduce(
        (
          accumulator: { [key: string]: NotificationPreference },
          current: NotificationPreference,
        ) => {
          // eslint-disable-next-line no-param-reassign
          accumulator[current.key] = current;
          return accumulator;
        },
        {},
      ),
    );

  const lastEditedTime = (key: string) => {
    const locale = window.navigator.language;
    const details = notificationPreferencesByKey[key];
    if (!details?.last_updated) {
      return <></>;
    }

    const dateObj = new Date(details.last_updated);
    const formattedDate = new Intl.DateTimeFormat(locale).format(dateObj);
    return formattedDate;
  };

  const isEnabled = (key: string) =>
    !!notificationPreferencesByKey[key]?.enabled;

  const updateNotificationPreference = (
    key: string,
    updatedNotificationPreference: NotificationPreference,
  ) => {
    setNotificationPreferencesByKey({
      ...notificationPreferencesByKey,
      [key]: updatedNotificationPreference,
    });
    dispatch(
      addMessageAction(
        intl.formatMessage(messages.notificationUpdateSuccessfull),
        'success',
      ),
    );
  };

  const toggleNotificationPreference = (key: string, status: boolean) => {
    setIsLoading(true);
    UserNotificationPreferencesService.putInternalApiNotificationPreferences(
      notifiableType,
      notifiableId,
      key,
      status,
    )
      .then(result => {
        setNotificationPreferencesByKey({
          ...notificationPreferencesByKey,
          [key]: result,
        });
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.notificationUpdateSuccessfull),
            'success',
          ),
        );
      })
      .catch(() => {
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.notificationUpdateErrored),
            'error',
          ),
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const subscribedStagesData = (key: string) => {
    const details = notificationPreferencesByKey[key];
    return (
      details?.custom_notification_preferences
        ?.map(preference => preference.entity_name)
        ?.join(', ') ?? ''
    );
  };

  const openEditModal = (key: string) => {
    setModalOpen(true);
    setSelectedNotificationPreference(key);
  };

  return (
    <>
      <TableBody>
        <TableRow className={styles.tableRow} data-testid="StagelandedRow">
          <TableCell className={styles.tableCell}>
            <Switch
              checked={isEnabled('stage_landed')}
              onChange={e => {
                if (
                  e.target.checked &&
                  isEmpty(subscribedStagesData('stage_landed'))
                ) {
                  // eslint-disable-next-line no-alert
                  dispatch(
                    addMessageAction(
                      intl.formatMessage(messages.stageMissingError),
                      'error',
                    ),
                  );
                } else {
                  void toggleNotificationPreference(
                    'stage_landed',
                    e.target.checked,
                  );
                }
              }}
            />
          </TableCell>
          <TableCell className={styles.tableCell}>
            Applicants lands on stage
          </TableCell>
          <TableCell
            className={classNames(styles.tableCell, styles.stagesColumn)}
          >
            <CustomTooltip
              title={subscribedStagesData('stage_landed').split(',').join('\n')}
              dense
              placement="top"
            >
              <div className={styles.truncatedText}>
                {subscribedStagesData('stage_landed')}
              </div>
            </CustomTooltip>
            <div>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link onClick={() => openEditModal('stage_landed')}>Edit</Link>
            </div>
          </TableCell>
          <TableCell className={styles.tableCell}>
            {lastEditedTime('stage_landed')}
          </TableCell>
        </TableRow>
        <TableRow
          className={styles.tableRow}
          data-testid="HiringGoalCreatedRow"
        >
          <TableCell className={styles.tableCell}>
            <Switch
              checked={isEnabled('hiring_goal_created')}
              onChange={e =>
                toggleNotificationPreference(
                  'hiring_goal_created',
                  e.target.checked,
                )
              }
            />
          </TableCell>
          <TableCell className={styles.tableCell}>
            Hiring goal created
          </TableCell>
          <TableCell className={styles.tableCell}>--</TableCell>
          <TableCell className={styles.tableCell}>
            {lastEditedTime('hiring_goal_created')}
          </TableCell>
        </TableRow>
        <TableRow
          className={styles.tableRow}
          data-testid="HiringGoalUpdatedRow"
        >
          <TableCell className={styles.tableCell}>
            <Switch
              checked={isEnabled('hiring_goal_updated')}
              onChange={e =>
                toggleNotificationPreference(
                  'hiring_goal_updated',
                  e.target.checked,
                )
              }
            />
          </TableCell>
          <TableCell className={styles.tableCell}>
            Hiring goal updated
          </TableCell>
          <TableCell className={styles.tableCell}>--</TableCell>
          <TableCell className={styles.tableCell}>
            {lastEditedTime('hiring_goal_updated')}
          </TableCell>
        </TableRow>
      </TableBody>
      {selectedNotificationPreference && (
        <CustomizeNotificationPreferenceModal
          notifiableType={notifiableType}
          notifiableId={notifiableId}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          updateNotificationPreference={updateNotificationPreference}
          notificationPreference={
            notificationPreferencesByKey[selectedNotificationPreference] ?? {
              key: selectedNotificationPreference,
              enabled: false,
            }
          }
        />
      )}
      {isLoading && <Loader fullScreen size="2rem" />}
    </>
  );
};
