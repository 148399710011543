const styles = theme => ({
  backButton: {
    width: 'fit-content',
    color: theme.palette.common.gray600,
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.spacing(2),
    cursor: 'pointer',
    textDecoration: 'none',
    // marginBottom: theme.spacing(6),
    '&.absolute': {
      position: 'absolute',
      [theme.breakpoints.down('sm')]: {
        left: theme.spacing(2),
      },
    },
  },
  backButtonIcon: {
    marginRight: theme.spacing(1),
  },
});

export default styles;
