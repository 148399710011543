import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  action: {
    defaultMessage: 'ACTION',
    id: `app.containers.WorkflowEditor.components.Rules.Action.action`,
  },
  actionType: {
    defaultMessage: 'Action Type',
    id: `app.containers.WorkflowEditor.components.Rules.Action.actionType`,
  },
  and: {
    defaultMessage: 'AND',
    id: `app.containers.WorkflowEditor.components.Rules.Action.and`,
  },
  cantBeBlank: {
    defaultMessage: "can't be blank",
    id: `app.containers.WorkflowEditor.components.Rules.Action.cantBeBlank`,
  },
  deleteActionNumber: {
    defaultMessage: 'Delete action {number}',
    id: `app.containers.WorkflowEditor.components.Rules.Action.deleteActionNumber`,
  },
  doesNotMeetAnyRule: {
    defaultMessage: 'If applicant does not meet any rule:',
    id: `app.containers.WorkflowEditor.components.Rules.Action.doesNotMeetAnyRule`,
  },
  doNotMoveApplicant: {
    defaultMessage: 'Do not move applicant',
    id: `app.containers.WorkflowEditor.components.Rules.Action.doNotMoveApplicant`,
  },
  moveApplicantToNextStage: {
    defaultMessage: 'Move applicant to next stage',
    id: `app.containers.WorkflowEditor.components.Rules.Action.moveApplicantToNextStage`,
  },
  moveApplicantToOpening: {
    defaultMessage: 'Move applicant to opening',
    id: `app.containers.WorkflowEditor.components.Rules.Action.moveApplicantToOpening`,
  },
  moveApplicantToStage: {
    defaultMessage: 'Move applicant to stage',
    id: `app.containers.WorkflowEditor.components.Rules.Action.moveApplicantToStage`,
  },
  resetPartnerStatus: {
    defaultMessage: 'Reset Partner Status',
    id: `app.containers.WorkflowEditor.components.Rules.Action.resetPartnerStatus`,
  },
  resumeConsentedBackgroundCheckReport: {
    defaultMessage: 'Resume consented background check report',
    id: `app.containers.WorkflowEditor.components.Rules.Action.resumeConsentedBackgroundCheckReport`,
  },
  runConsentedBackgroundCheckReport: {
    defaultMessage: 'Run consented background check report',
    id: `app.containers.WorkflowEditor.components.Rules.Action.runConsentedBackgroundCheckReport`,
  },
  switchApplicantData: {
    defaultMessage: 'Switch applicant data',
    id: `app.containers.WorkflowEditor.components.Rules.Action.switchApplicantData`,
  },
  updateApplicantData: {
    defaultMessage: 'Update applicant data',
    id: `app.containers.WorkflowEditor.components.Rules.Action.updateApplicantData`,
  },
  createOrUpdateWxWorker: {
    defaultMessage: 'Create or update WX worker',
    id: `app.containers.WorkflowEditor.components.Rules.Action.createOrUpdateWxWorker`,
  },
  incrementApplicantData: {
    defaultMessage: 'Increment applicant data',
    id: `app.containers.WorkflowEditor.components.Rules.Action.incrementApplicantData`,
  },
  markApplicantDuplicate: {
    defaultMessage: 'Mark Applicant Duplicate',
    id: `app.containers.WorkflowEditor.components.Rules.Action.markApplicantDuplicate`,
  },
});
