import { defineMessages } from 'react-intl';

export default defineMessages({
  streetName: {
    id: 'app.components.ApplicantAddress.streetName',
    defaultMessage: 'Street Name',
    description: 'Label for the street name input field',
  },
  address2: {
    id: 'app.components.ApplicantAddress.address2',
    defaultMessage: 'Address 2',
    description: 'Label for the address 2 input field',
  },
  city: {
    id: 'app.components.AppplicantAddress.city',
    defaultMessage: 'City',
    description: 'Label for the city input field',
  },
  county: {
    id: 'app.components.ApplicantAddress.county',
    defaultMessage: 'County',
    description: 'Label for the county input field',
  },
  state: {
    id: 'app.components.ApplicantAddress.state',
    defaultMessage: 'State',
    description: 'Label for the state input field',
  },
  zipCode: {
    id: 'app.components.ApplicantAddress.zipCode',
    defaultMessage: 'ZIP Code',
    description: 'Label for the ZIP code input field',
  },
  country: {
    id: 'app.components.ApplicantAddress.country',
    defaultMessage: 'Country',
    description: 'Label for the country input field',
  },
});
