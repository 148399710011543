import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  action: {
    id: `app.shared.messages.global.action`,
    defaultMessage: 'ACTION',
  },
  advancedSettings: {
    id: `app.shared.messages.global.advancedSettings`,
    defaultMessage: 'Advanced Settings',
  },
  agree: {
    id: `app.shared.messages.global.agree`,
    defaultMessage: 'Agree',
  },
  and: {
    id: `app.shared.messages.global.and`,
    defaultMessage: 'AND',
  },
  apiError: {
    id: 'app.shared.messages.global.apiError',
    defaultMessage:
      'An error occurred. Please check your network or try again later.',
  },
  apply: {
    id: `app.shared.messages.global.apply`,
    defaultMessage: 'Apply',
  },
  applicant: {
    id: `app.shared.messages.global.applicant`,
    defaultMessage: 'applicant',
  },
  applicantRejected: {
    id: `app.shared.messages.global.applicantRejected`,
    defaultMessage: 'Applicant has been rejected',
  },
  applicants: {
    id: `app.shared.messages.global.applicants`,
    defaultMessage: 'Applicants',
  },
  applicantOutOfArea: {
    id: `app.shared.messages.global.applicantOutOfArea`,
    defaultMessage: 'Applicant may not be in the area.',
  },
  applied: {
    id: `app.shared.messages.global.applied`,
    defaultMessage: 'Applied',
  },
  appliedDate: {
    id: `app.shared.messages.global.appliedDate`,
    defaultMessage: 'Applied Date',
  },
  approve: {
    id: `app.shared.messages.global.approve`,
    defaultMessage: 'Approve',
  },
  approved: {
    id: `app.shared.messages.global.approved`,
    defaultMessage: 'Approved',
  },
  askDelete: {
    id: `app.shared.messages.global.askDelete`,
    defaultMessage: 'Are you sure you want to delete?',
  },
  assignedUser: {
    id: `app.shared.messages.global.assignedUser`,
    defaultMessage: 'Assigned User',
  },
  at: {
    id: `app.shared.messages.global.at`,
    defaultMessage: 'At',
  },
  back: {
    id: `app.shared.messages.global.back`,
    defaultMessage: 'Back',
  },
  beforeunload: {
    id: `app.shared.messages.global.beforeunload`,
    defaultMessage: 'Any unsaved changes will be lost!',
  },
  beta: {
    id: `app.shared.messages.global.beta`,
    defaultMessage: 'BETA',
  },
  boostedApplicant: {
    id: `app.shared.messages.global.boostedApplicant`,
    defaultMessage: 'This applicant was acquired using Boost.',
  },
  cancel: {
    id: `app.shared.messages.global.cancel`,
    defaultMessage: 'Cancel',
  },
  cantBeBlank: {
    id: `app.shared.messages.global.cantBeBlank`,
    defaultMessage: "can't be blank",
  },
  changesSavedSuccessfully: {
    id: `app.shared.messages.global.changesSavedSuccessfully`,
    defaultMessage: 'Changes saved successfully',
  },
  charactersLimit: {
    id: `app.shared.messages.global.charactersLimit`,
    defaultMessage: `{current}/{limit} Characters`,
  },
  clear: {
    id: `app.shared.messages.global.clear`,
    defaultMessage: 'Clear',
  },
  clickHere: {
    id: `app.shared.messages.global.clickHere`,
    defaultMessage: 'Click Here',
  },
  close: {
    id: `app.shared.messages.global.close`,
    defaultMessage: 'Close',
  },
  closeConfirmationMessage: {
    id: `app.shared.messages.global.closeConfirmationMessage`,
    defaultMessage: 'Are you sure? You will lose all unsaved progress.',
  },
  concepts: {
    id: `app.shared.messages.global.concepts`,
    defaultMessage: 'Applicant Mapping',
  },
  condition: {
    id: `app.shared.messages.condition`,
    defaultMessage: 'CONDITION',
  },
  confirm: {
    id: `app.shared.messages.global.confirm`,
    defaultMessage: 'Confirm',
  },
  confirmDelete: {
    id: `app.shared.messages.global.confirmDelete`,
    defaultMessage: `Yes, Delete`,
  },
  continue: {
    id: `app.shared.messages.global.continue`,
    defaultMessage: 'Continue',
  },
  copy: {
    id: `app.shared.messages.global.copy`,
    defaultMessage: 'Copy',
  },
  copySuccess: {
    id: `app.shared.messages.global.copySuccess`,
    defaultMessage: 'Successfully copied to clipboard',
  },
  custom: {
    id: `app.shared.messages.global.custom`,
    defaultMessage: 'Custom',
  },
  customerAttribute: {
    id: `app.shared.messages.global.customerAttribute`,
    defaultMessage: 'Customer Attribute',
  },
  customLabel: {
    id: `app.shared.messages.global.customLabel`,
    defaultMessage: 'Custom Label',
  },
  dataKey: {
    id: `app.shared.messages.global.dataKey`,
    defaultMessage: 'Data Key',
  },
  addDataKey: {
    id: `app.shared.messages.global.addDataKey`,
    defaultMessage: 'Add Data Key',
  },
  dateOfBirth: {
    id: `app.shared.messages.global.dateOfBirth`,
    defaultMessage: 'Date of Birth',
  },
  datePicker: {
    id: `app.shared.messages.datePicker`,
    defaultMessage: 'Date Picker',
  },
  delete: {
    id: `app.shared.messages.global.delete`,
    defaultMessage: 'Delete',
  },
  discard: {
    id: `app.shared.messages.global.discard`,
    defaultMessage: 'Discard',
  },
  discardChanges: {
    id: `app.shared.messages.global.discardChanges`,
    defaultMessage: 'Discard Changes',
  },
  duplicateApplicant: {
    id: `app.shared.messages.global.duplicateApplicant`,
    defaultMessage: 'This applicant may be a duplicate.',
  },
  download: {
    id: `app.shared.messages.global.download`,
    defaultMessage: 'Download',
  },
  edit: {
    id: `app.shared.messages.global.edit`,
    defaultMessage: 'Edit',
  },
  edited: {
    id: `app.shared.messages.global.edited`,
    defaultMessage: 'Edited',
  },
  email: {
    id: `app.shared.messages.global.email`,
    defaultMessage: 'Email',
  },
  emailContent: {
    id: `app.shared.messages.global.emailContent`,
    defaultMessage: 'Email Content',
  },
  emailSubject: {
    id: `app.shared.messages.global.emailSubject`,
    defaultMessage: 'Email subject',
  },
  error: {
    id: `app.shared.messages.global.error`,
    defaultMessage: 'Error Message',
  },
  emailUnsubscribed: {
    id: `app.shared.messages.global.emailUnsubscribed`,
    defaultMessage: 'Applicant is not subscribed to Email.',
  },
  exit: {
    id: `app.shared.messages.global.exit`,
    defaultMessage: 'Exit',
  },
  from: {
    id: `app.shared.messages.global.from`,
    defaultMessage: 'From',
  },
  getApplicants: {
    id: `app.shared.messages.global.getApplicants`,
    defaultMessage: 'Post to Job Boards',
  },
  hasUnreadMessages: {
    id: `app.shared.messages.global.hasUnreadMessages`,
    defaultMessage: 'Has Unread Messages',
  },
  help: {
    id: `app.shared.messages.global.help`,
    defaultMessage: 'Help',
  },
  idle: {
    id: `app.shared.messages.global.idle`,
    defaultMessage: 'Idle',
  },
  idleSince: {
    id: `app.shared.messages.global.idleSince`,
    defaultMessage: 'Idle Since',
  },
  job: {
    id: `app.shared.messages.global.job`,
    defaultMessage: 'Job',
  },
  jobs: {
    id: `app.shared.messages.global.jobs`,
    defaultMessage: 'Jobs',
  },
  lastEditedBy: {
    id: `app.shared.messages.global.lastEditedBy`,
    defaultMessage: 'Last edited by',
  },
  lastUserNote: {
    id: `app.shared.messages.global.lastUserNote`,
    defaultMessage: 'Last User Note',
  },
  landed: {
    id: `app.shared.messages.global.landed`,
    defaultMessage: 'Landed',
  },
  later: {
    id: `app.shared.messages.global.later`,
    defaultMessage: 'Later',
  },
  learnMore: {
    id: `app.shared.messages.global.learnMore`,
    defaultMessage: 'Learn more',
  },
  location: {
    id: `app.shared.messages.global.location`,
    defaultMessage: 'Location',
  },
  locationGroup: {
    id: `app.shared.messages.global.locationGroup`,
    defaultMessage: 'Location Group',
  },
  loading: {
    id: `app.shared.messages.global.loading`,
    defaultMessage: 'Loading...',
  },
  locations: {
    id: `app.shared.messages.global.locations`,
    defaultMessage: 'Locations',
  },
  message: {
    id: `app.shared.messages.global.message`,
    defaultMessage: 'Message',
  },
  moveTo: {
    id: `app.shared.messages.global.moveTo`,
    defaultMessage: 'Move to',
  },
  movedApplicantSuccessfully: {
    id: `app.shared.messages.global.movedApplicantSuccessfully`,
    defaultMessage: 'Moved applicant successfully',
  },
  no: {
    id: `app.shared.messages.global.no`,
    defaultMessage: 'No',
  },
  noOptionsAvailable: {
    id: `app.shared.messages.global.noOptionsAvailable`,
    defaultMessage: 'No options available',
  },
  noResultsAvailable: {
    id: `app.shared.messages.global.noResultsAvailable`,
    defaultMessage: `No {resultType} Available`,
  },
  noSuchDataKey: {
    id: `app.shared.messages.global.noSuchDataKey`,
    defaultMessage: 'No such data key',
  },
  noSuchKey: {
    id: `app.shared.messages.global.noSuchKey`,
    defaultMessage: 'No such key',
  },
  none: {
    id: `app.shared.messages.global.none`,
    defaultMessage: 'None',
  },
  now: {
    id: `app.shared.messages.global.now`,
    defaultMessage: 'Now',
  },
  on: {
    id: `app.shared.messages.global.on`,
    defaultMessage: 'On',
  },
  opening: {
    id: `app.shared.messages.global.opening`,
    defaultMessage: 'Opening',
  },
  openings: {
    id: `app.shared.messages.global.openings`,
    defaultMessage: 'Openings',
  },
  operator: {
    id: `app.shared.messages.operator`,
    defaultMessage: 'Operator',
  },
  or: {
    id: `app.shared.messages.or`,
    defaultMessage: 'OR',
  },
  position: {
    id: `app.shared.messages.global.position`,
    defaultMessage: 'Position',
  },
  preview: {
    id: `app.shared.messages.global.preview`,
    defaultMessage: 'Preview',
  },
  progress: {
    id: `app.shared.messages.global.progress`,
    defaultMessage: 'Progress',
  },
  phone: {
    id: `app.shared.messages.global.phone`,
    defaultMessage: 'Phone',
  },
  phoneNumber: {
    id: `app.shared.messages.global.phoneNumber`,
    defaultMessage: 'Phone Number',
  },
  applicationLocale: {
    id: `app.shared.messages.global.applicationLocale`,
    defaultMessage: 'Application Language',
  },
  daysLeftInStage: {
    id: `app.shared.messages.global.daysLeftInStage`,
    defaultMessage: 'Days Left In Stage',
  },
  saveAndPreview: {
    id: `app.shared.messages.global.saveAndPreview`,
    defaultMessage: 'Save & Preview',
  },
  privacy: {
    id: `app.shared.messages.global.privacy`,
    defaultMessage: 'Privacy',
  },
  reason: {
    id: `app.shared.messages.reason`,
    defaultMessage: 'Reason',
  },
  recipientsCount: {
    id: `app.shared.messages.global.recipientsCount`,
    defaultMessage:
      '{count, plural, one {{count} recipient} other {{count} recipients}}',
  },
  recipients: {
    id: `app.shared.messages.global.recipients`,
    defaultMessage: 'Recipients',
  },
  recollect: {
    id: `app.shared.messages.global.recollect`,
    defaultMessage: 'Re-collect',
  },
  remove: {
    id: `app.shared.messages.global.remove`,
    defaultMessage: 'Remove',
  },
  rule: {
    id: `app.shared.messages.global.rule`,
    defaultMessage: 'Rule',
  },
  moreActions: {
    id: `app.shared.messages.global.moreActions`,
    defaultMessage: 'More actions',
  },
  moveToNextStage: {
    id: `app.shared.messages.global.moveToNextStage`,
    defaultMessage: 'Move to next stage',
  },
  name: {
    id: `app.shared.messages.global.name`,
    defaultMessage: 'Name',
  },
  reject: {
    id: `app.shared.messages.global.reject`,
    defaultMessage: 'Reject',
  },
  rejected: {
    id: `app.shared.messages.global.rejected`,
    defaultMessage: 'X Rejected',
  },
  requestedBy: {
    id: `app.shared.messages.global.requestedBy`,
    defaultMessage: 'Requested By ',
  },
  requestRevision: {
    id: `app.shared.messages.global.requestRevision`,
    defaultMessage: 'Request Revision',
  },
  required: {
    id: `app.shared.messages.global.required`,
    defaultMessage: 'Required',
  },
  reschedule: {
    id: `app.shared.messages.global.reschedule`,
    defaultMessage: 'Reschedule',
  },
  sample: {
    id: `app.shared.messages.global.sample`,
    defaultMessage: 'Sample',
  },
  save: {
    id: `app.shared.messages.global.save`,
    defaultMessage: 'Save',
  },
  saveChanges: {
    id: `app.shared.messages.global.saveChanges`,
    defaultMessage: 'Save Changes',
  },
  schedule: {
    id: `app.shared.messages.global.schedule`,
    defaultMessage: 'Schedule',
  },
  scheduleEmail: {
    id: `app.shared.messages.global.scheduleEmail`,
    defaultMessage: 'Schedule Email',
  },
  search: {
    id: `app.shared.messages.global.search`,
    defaultMessage: 'Search Applicants',
  },
  searchUsers: {
    id: `app.shared.messages.global.searchUsers`,
    defaultMessage: 'Search Users',
  },
  select: {
    id: `app.shared.messages.global.select`,
    defaultMessage: 'Select',
  },
  send: {
    id: `app.shared.messages.global.send`,
    defaultMessage: 'Send',
  },
  sendEmail: {
    id: `app.shared.messages.global.sendEmail`,
    defaultMessage: 'Send Email',
  },
  scheduleSms: {
    id: `app.shared.messages.global.scheduleSms`,
    defaultMessage: 'Schedule SMS',
  },
  sendSms: {
    id: `app.shared.messages.global.sendSms`,
    defaultMessage: 'Send SMS',
  },
  sendMessages: {
    id: `app.shared.messages.global.sendMessages`,
    defaultMessage: 'Send Messages',
  },
  sendFrom: {
    id: `app.shared.messages.global.sendFrom`,
    defaultMessage: 'Send From',
  },
  settings: {
    id: `app.shared.messages.global.settings`,
    defaultMessage: 'Settings',
  },
  showStageInApplicantPortal: {
    id: `app.shared.messages.global.showStageInApplicantPortal`,
    defaultMessage: 'Show stage in applicant portal progress tracker',
  },
  success: {
    id: `app.shared.messages.global.success`,
    defaultMessage: 'Success',
  },
  signIn: {
    id: `app.shared.messages.global.signIn`,
    defaultMessage: 'Sign In',
  },
  smsContent: {
    id: `app.shared.messages.global.smsContent`,
    defaultMessage: 'SMS Content',
  },
  smsUnsubscribed: {
    id: `app.shared.messages.global.smsUnsubscribed`,
    defaultMessage: 'Applicant is not subscribed to SMS.',
  },
  stage: {
    id: `app.shared.messages.global.stage`,
    defaultMessage: 'Stage',
  },
  stages: {
    id: `app.shared.messages.global.stages`,
    defaultMessage: 'stages',
  },
  status: {
    id: `app.shared.messages.global.status`,
    defaultMessage: 'Status',
  },
  subject: {
    id: `app.shared.messages.global.subject`,
    defaultMessage: 'Subject',
  },
  submit: {
    id: `app.shared.messages.global.submit`,
    defaultMessage: 'Submit',
  },
  terms: {
    id: `app.shared.messages.global.terms`,
    defaultMessage: 'Terms',
  },
  to: {
    id: `app.shared.messages.global.to`,
    defaultMessage: 'to',
  },
  unsavedProgress: {
    id: `app.shared.messages.global.unsavedProgress`,
    defaultMessage: 'Are you sure? You will lose all unsaved progress.',
  },
  enterValue: {
    id: `app.shared.messages.global.enterValue`,
    defaultMessage: 'Enter a value for this field',
  },
  unableToDeliverSms: {
    id: `app.shared.messages.global.unableToDeliverSms`,
    defaultMessage: 'Unable to deliver SMS.',
  },
  update: {
    id: `app.shared.messages.global.update`,
    defaultMessage: 'Update',
  },
  user: {
    id: `app.shared.messages.global.user`,
    defaultMessage: 'User',
  },
  utmSource: {
    id: `app.shared.messages.global.utmSource`,
    defaultMessage: 'UTM Source',
  },
  utmCampaign: {
    id: `app.shared.messages.global.utmCampaign`,
    defaultMessage: 'UTM Campaign',
  },
  value: {
    id: `app.shared.messages.value`,
    defaultMessage: 'Value',
  },
  when: {
    id: `app.shared.messages.global.when`,
    defaultMessage: 'when',
  },
  yes: {
    id: `app.shared.messages.global.yes`,
    defaultMessage: 'Yes',
  },
  nextStageUnavailable: {
    id: `app.shared.messages.global.nextStageUnavailable`,
    defaultMessage:
      "The applicant can't be moved to the next stage because they are already in the last stage.",
  },
  rejectStageUnavailable: {
    id: `app.shared.messages.global.rejectStageUnavailable`,
    defaultMessage:
      "The applicant can't be rejected because they are already in the rejected stage.",
  },
  yourImportHasStarted: {
    id: `app.shared.messages.global.yourImportHasStarted`,
    defaultMessage:
      'Your import has started, we will send you an email when it is complete.',
  },
  userRequired: {
    id: `app.shared.messages.global.userRequired`,
    defaultMessage: 'User is required',
  },
  updateFieldSuccess: {
    id: `app.shared.messages.global.updateFieldSuccess`,
    defaultMessage: 'Updated field successfully',
  },
  updateInfoSuccess: {
    id: `app.shared.messages.global.updateInfoSuccess`,
    defaultMessage: 'Updated info successfully',
  },
  requiredFieldMessage: {
    id: `app.shared.messages.global.requiredFieldMessage`,
    defaultMessage: 'Required Field',
  },
  days: {
    id: `app.shared.messages.global.days`,
    defaultMessage: 'Days',
  },
  exportToWx: {
    id: `app.shared.messages.global.exportToWx`,
    defaultMessage: 'Export to WX',
  },

  frFR: {
    defaultMessage: 'French 🇫🇷',
    description: 'Indicate that the application was done in french',
    id: 'app.shared.locales.frFR',
  },
  itIT: {
    defaultMessage: 'Italian 🇮🇹',
    description: 'Indicate that the application was done in italian',
    id: 'app.shared.locales.itIT',
  },
  deDE: {
    defaultMessage: 'German 🇩🇪',
    description: 'Indicate that the application was done in german',
    id: 'app.shared.locales.deDE',
  },
  esES: {
    defaultMessage: 'Spanish 🇪🇸',
    description: 'Indicate that the application was done in spanish',
    id: 'app.shared.locales.esES',
  },
  enUS: {
    defaultMessage: 'English 🇺🇸',
    description: 'Indicate that the application was done in american english',
    id: 'app.shared.locales.enUS',
  },
  enGB: {
    defaultMessage: 'English 🇬🇧',
    description: 'Indicate that the application was done in UK english',
    id: 'app.shared.locales.enGB',
  },
  hiIN: {
    defaultMessage: 'Hindi 🇮🇳',
    description: 'Indicate that the application was done in hindi',
    id: 'app.shared.locales.hiIN',
  },
  ar: {
    defaultMessage: 'Arabic 🇦🇪',
    description: 'Indicate that the application was done in arabic',
    id: 'app.shared.locales.ar',
  },
  bgBG: {
    defaultMessage: 'Bulgarian 🇧🇬',
    description: 'Indicate that the application was done in bulgarian',
    id: 'app.shared.locales.bgBG',
  },
  zhCN: {
    defaultMessage: 'Chinese 🇨🇳',
    description: 'Indicate that the application was done in chinese',
    id: 'app.shared.locales.zhCN',
  },
  zhTW: {
    defaultMessage: 'Chinese (traditional) 🇨🇳',
    description:
      'Indicate that the application was done in chinese (traditional)',
    id: 'app.shared.locales.zhTW',
  },
  csCZ: {
    defaultMessage: 'Czech 🇨🇿',
    description: 'Indicate that the application was done in czech',
    id: 'app.shared.locales.csCZ',
  },
  nlNL: {
    defaultMessage: 'Dutch 🇳🇱',
    description: 'Indicate that the application was done in dutch',
    id: 'app.shared.locales.nlNL',
  },
  fiFI: {
    defaultMessage: 'Finnish 🇫🇮',
    description: 'Indicate that the application was done in finnish',
    id: 'app.shared.locales.fiFI',
  },
  elGR: {
    defaultMessage: 'Greek 🇬🇷',
    description: 'Indicate that the application was done in greek',
    id: 'app.shared.locales.elGR',
  },
  heIL: {
    defaultMessage: 'Hebrew 🇮🇱',
    description: 'Indicate that the application was done in hebrew',
    id: 'app.shared.locales.heIL',
  },
  idID: {
    defaultMessage: 'Indonesian 🇮🇳',
    description: 'Indicate that the application was done in indonesian',
    id: 'app.shared.locales.idID',
  },
  jaJP: {
    defaultMessage: 'Japanese 🇯🇵',
    description: 'Indicate that the application was done in japanese',
    id: 'app.shared.locales.jaJP',
  },
  knIN: {
    defaultMessage: 'Kannada',
    description: 'Indicate that the application was done in kannada',
    id: 'app.shared.locales.knIN',
  },
  koKR: {
    defaultMessage: 'Korean 🇰🇷',
    description: 'Indicate that the application was done in korean',
    id: 'app.shared.locales.koKR',
  },
  msMY: {
    defaultMessage: 'Malay 🇮🇳',
    description: 'Indicate that the application was done in malay',
    id: 'app.shared.locales.msMY',
  },
  mrIN: {
    defaultMessage: 'Marathi',
    description: 'Indicate that the application was done in marathi',
    id: 'app.shared.locales.mrIN',
  },
  noNO: {
    defaultMessage: 'Norwegian 🇳🇴',
    description: 'Indicate that the application was done in norwegian',
    id: 'app.shared.locales.noNO',
  },
  plPL: {
    defaultMessage: 'Polish 🇵🇱',
    description: 'Indicate that the application was done in polish',
    id: 'app.shared.locales.plPL',
  },
  ptBR: {
    defaultMessage: 'Portuguese 🇵🇹',
    description: 'Indicate that the application was done in portuguese',
    id: 'app.shared.locales.ptBR',
  },
  roRO: {
    defaultMessage: 'Romanian 🇷🇴',
    description: 'Indicate that the application was done in romanian',
    id: 'app.shared.locales.roRO',
  },
  ruRU: {
    defaultMessage: 'Russian 🇷🇺',
    description: 'Indicate that the application was done in russian',
    id: 'app.shared.locales.ruRU',
  },
  svSE: {
    defaultMessage: 'Swedish 🇸🇪',
    description: 'Indicate that the application was done in swedish',
    id: 'app.shared.locales.svSE',
  },
  taIN: {
    defaultMessage: 'Tamil',
    description: 'Indicate that the application was done in tamil',
    id: 'app.shared.locales.taIN',
  },
  teIN: {
    defaultMessage: 'Telugu 🇷🇺',
    description: 'Indicate that the application was done in telugu',
    id: 'app.shared.locales.teIN',
  },
  thTH: {
    defaultMessage: 'Thai 🇹🇭',
    description: 'Indicate that the application was done in thai',
    id: 'app.shared.locales.thTH',
  },
  trTR: {
    defaultMessage: 'Turkish 🇹🇷',
    description: 'Indicate that the application was done in turkish',
    id: 'app.shared.locales.trTR',
  },
  viVN: {
    defaultMessage: 'Vietnamese 🇻🇳',
    description: 'Indicate that the application was done in vietnamese',
    id: 'app.shared.locales.viVN',
  },
});

export default messages;
