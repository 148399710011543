import { defineMessages } from 'react-intl';

export default defineMessages({
  hiringGoals: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.DistributeApplicantsRuleStage.components.DistributeRuleRow.hiringGoals`,
    defaultMessage: 'Hiring Goals',
  },
  account: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.DistributeApplicantsRuleStage.components.DistributeRuleRow.account`,
    defaultMessage: 'Account',
  },
  location: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.DistributeApplicantsRuleStage.components.DistributeRuleRow.location`,
    defaultMessage: 'Location',
  },
  locations: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.DistributeApplicantsRuleStage.components.DistributeRuleRow.locations`,
    defaultMessage: 'Locations',
  },
  noResultsAvailable: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.DistributeApplicantsRuleStage.components.DistributeRuleRow.noResultsAvailable`,
    defaultMessage: 'No {resultType} Available',
  },
  opening: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.DistributeApplicantsRuleStage.components.DistributeRuleRow.opening`,
    defaultMessage: 'Opening',
  },
  openings: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.DistributeApplicantsRuleStage.components.DistributeRuleRow.openings`,
    defaultMessage: 'Openings',
  },
  requiredFieldMessage: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.DistributeApplicantsRuleStage.components.DistributeRuleRow.requiredFieldMessage`,
    defaultMessage: 'Required Field',
  },
  search: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.DistributeApplicantsRuleStage.components.DistributeRuleRow.search`,
    defaultMessage: 'Search',
  },
  stage: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.DistributeApplicantsRuleStage.components.DistributeRuleRow.stage`,
    defaultMessage: 'Stage',
  },
  stages: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.DistributeApplicantsRuleStage.components.DistributeRuleRow.stages`,
    defaultMessage: 'stages',
  },
  startOfWorkflow: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.DistributeApplicantsRuleStage.components.DistributeRuleRow.startOfWorkflow`,
    defaultMessage: 'Start of workflow',
  },
  targetDistribution: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.DistributeApplicantsRuleStage.components.DistributeRuleRow.targetDistribution`,
    defaultMessage: '% Target Distribution',
  },
  disabledFields: {
    id: 'app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.DistributeApplicantsRuleStage.components.DistributeRuleRow.disabledFields',
    defaultMessage: 'Opening must be selected first',
  },
  deleteDistRuleNumber: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.DistributeApplicantsRuleStage.components.DistributeRuleRow.deleteDistRuleNumber`,
    defaultMessage: 'Delete distribution rule {number}',
  },
});
