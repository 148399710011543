import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  instructions: {
    fontFamily: theme.typography.body2.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
    color: theme.palette.common.gray800,
    fontWeight: 400,
  },
  confirmButton: {
    borderRadius: '5px',
    height: '32px',
    boxShadow: '0px 1px 2px rgba(19, 30, 64, 0.08)',
    whiteSpace: 'nowrap',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  exportIcon: {
    fontSize: theme.spacing(1.5),
    margin: theme.spacing(0, 0, -0.25, 0.75),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  manageDataValidations: {
    color: theme.palette.common.blue400,
    lineHeight: theme.typography.body2.lineHeight,
    fontSize: theme.typography.body2.fontSize,
    fontFamily: theme.typography.body2.fontFamily,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  manageDataValidationsWrapper: {
    marginRight: 'auto',
    width: 'auto',
  },
  buttonGroup: {
    display: 'flex',
    gap: '1rem',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
      gap: '1rem',
    },
  },
  onlyOneButton: {
    justifyContent: 'flex-end',
  },
}));

export default useStyles;
